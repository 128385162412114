import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'src/store/useDispatch';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';

import { StyledButton, StyledClosebtn, StyledContainer } from './styled';
import { setAppAlert } from 'src/store/slice/appAlert';

const QrPopUp = ({ userId, onClose }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [qrCodeBase64, setQrCodeBase64] = useState<string | null>(null);
  const [otp, setOtp] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, onClose]);

  const fetchQrCode = () => {
    try {
      request
        .get(`${PUBLIC_URL}/v1/users/${userId}/add-2fa`)
        .then(({ data }) => setQrCodeBase64(data.qrUrl))
        .catch(({ response }) =>
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Something went wrong.',
              isSuccess: false,
            }),
          ),
        );
    } catch (error) {
      console.error('Error fetching QR code:', error);
    }
  };

  const submitOtp = async () => {
    try {
      request
        .post(`${PUBLIC_URL}/v1/users/${userId}/add-2fa`, {
          code: otp,
        })
        .then(({ data }) => {
          dispatch(
            setAppAlert({
              message: data?.msg || 'You have passed verification.',
              isSuccess: true,
            }),
          );
        })
        .catch(({ response }) => {
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Something went wrong.',
              isSuccess: false,
            }),
          );
        })
        .finally(() => onClose());
    } catch (error) {
      console.error('Error submitting OTP code:', error);
    }
  };

  useEffect(() => {
    fetchQrCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {qrCodeBase64 ? (
        <StyledContainer ref={popupRef}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <StyledClosebtn onClick={() => onClose()} style={{ marginLeft: 'auto' }}>
              +
            </StyledClosebtn>
            {qrCodeBase64 ? (
              <img src={qrCodeBase64} alt="QR Code" style={{ width: '80%' }} />
            ) : (
              'Loading....'
            )}
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px', textAlign: 'center' }}
            >
              <div>Scan QR code using Google Authenticator app and enter the code</div>
              <input
                style={{ height: '40px' }}
                value={otp || ''}
                onChange={(e) => setOtp(e.currentTarget.value)}
              />
              <br />
              <StyledButton $isOtp={!!otp} onClick={submitOtp}>
                Submit
              </StyledButton>
            </div>
          </div>
        </StyledContainer>
      ) : null}
    </>
  );
};

export default QrPopUp;
