import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from '../../store/useDispatch';
import { useSelector } from 'react-redux';
import { setAppAlert } from 'src/store/slice/appAlert';

import { ReactComponent as EyeIcon } from '../../assets/Eye.svg';
import { ReactComponent as EyeClosedIcon } from '../../assets/EyeClosed.svg';

import Loader from 'src/components/Loader/Loader';

import { PUBLIC_URL } from 'src/configs';
import request from 'src/request';
import { PASSWORD_VALIDATION_ERROR, validatePassword } from 'src/constants';
import { ROUTE_EARLY_REGISTRATION } from 'src/routes';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector(({ user }) => user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    updateSubmitButtonState(event.target.value, newPassword, confirmPassword, isPasswordValid);
  };

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    setNewPassword(newPasswordValue);
    setIsPasswordValid(validatePassword(newPasswordValue));
    updateSubmitButtonState(oldPassword, newPasswordValue, confirmPassword, isPasswordValid);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    updateSubmitButtonState(oldPassword, newPassword, event.target.value, isPasswordValid);
  };

  const updateSubmitButtonState = (oldPassword, newPassword, confirmPassword, isPasswordValid) => {
    setIsSubmitDisabled(
      !(oldPassword && newPassword && confirmPassword === newPassword && isPasswordValid),
    );
  };

  const handleSubmit = () => {
    setIsLoading(true);
    request
      .patch(`${PUBLIC_URL}/v1/users/${user.id}/password`, {
        old_password: oldPassword,
        new_password: newPassword,
      })
      .then(({ data }) => {
        dispatch(
          setAppAlert({
            message: `${data.message}. You will be redirected to login page in a moment.`,
            isSuccess: !!data.message,
          }),
        );
        setTimeout(() => {
          navigate(ROUTE_EARLY_REGISTRATION);
        }, 5000);
      })
      .catch(({ response }) => {
        dispatch(
          setAppAlert({
            message: response?.data?.error || 'Something went wrong',
            isSuccess: false,
          }),
        );
      })
      .finally(() => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setIsSubmitDisabled(true);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '83vh',
        }}
      >
        <div style={{ maxWidth: '300px', margin: 'auto' }}>
          <div style={{ marginBottom: '10px', position: 'relative' }}>
            <label>Old Password:</label>
            <br />
            <input
              style={{
                width: '100%',
                height: '40px',
                borderRadius: '40px',
                border: '2px solid #172A4F',
                padding: '0 1.5vw',
                color: '#111',
                outline: 'none',
              }}
              type={showOldPassword ? 'text' : 'password'}
              value={oldPassword}
              onChange={handleOldPasswordChange}
            />
            {!showOldPassword ? (
              <EyeIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '50%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
            ) : (
              <EyeClosedIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '50%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
            )}
          </div>
          <div style={{ marginBottom: '10px', position: 'relative' }}>
            <label>New Password:</label>
            <br />
            <input
              style={{
                width: '100%',
                height: '40px',
                borderRadius: '40px',
                border: '2px solid #172A4F',
                padding: '0 1.5vw',
                color: '#111',
                outline: 'none',
              }}
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            {!isPasswordValid && <p style={{ color: 'red' }}>{PASSWORD_VALIDATION_ERROR}</p>}
            {!showNewPassword ? (
              <EyeIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: isPasswordValid ? '50%' : '23.5%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            ) : (
              <EyeClosedIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: isPasswordValid ? '50%' : '23.5%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            )}
          </div>
          <div style={{ marginBottom: '10px', position: 'relative', paddingBottom: '20px' }}>
            <label>Confirm New Password:</label>
            <input
              style={{
                width: '100%',
                height: '40px',
                borderRadius: '40px',
                border: '2px solid #172A4F',
                padding: '0 1.5vw',
                color: '#111',
                outline: 'none',
              }}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {!showConfirmPassword ? (
              <EyeIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '38%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            ) : (
              <EyeClosedIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '38%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
          </div>
          <button
            style={{
              width: '100%',
              backgroundColor: isSubmitDisabled || isLoading ? '#ccc' : '#00FEB9',
              cursor: isSubmitDisabled || isLoading ? 'auto' : 'pointer',
              color: '#111',
              textAlign: 'center',
              fontWeight: 600,
              border: 'none',
              borderRadius: '25px',
              marginTop: '2vh',
              height: '36px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '24px 0',
            }}
            onClick={handleSubmit}
            disabled={isSubmitDisabled || isLoading}
          >
            {isLoading ? <Loader /> : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
