import React, { FC, SVGProps } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_ORDERS } from 'src/routes';

interface IAsideLink {
  title: string;
  to: string;
  src: FC<SVGProps<SVGSVGElement>>;
}

const AsideLink: FC<IAsideLink> = ({ title, to, src: Icon }) => {
  const { pathname } = useLocation();
  const isActive = pathname === `${ROUTE_ORDERS}/${to}` ? 'active' : undefined;

  return (
    <li>
      <Link to={to} className={isActive}>
        <Icon />
        <span>{title}</span>
      </Link>
    </li>
  );
};

export default AsideLink;
