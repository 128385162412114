import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import { ReactComponent as BgSmall } from '../../assets/BgSmall.svg';
import { ReactComponent as BgLarge } from '../../assets/BgLarge.svg';

import Loader from 'src/components/Loader/Loader';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';
import { getSign } from 'src/constants';
import { ROUTE_FORGOT_PW_SUBMIT_OTP } from 'src/routes';

import './index.css';

const RequestRtp = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sign = getSign();

  const submitEmail = () => {
    setLoading(true);
    request
      .post(`${PUBLIC_URL}/auth/forgot-password/request-code`, { email })
      .then(({ data }) => {
        dispatch(
          setAppAlert({
            message: data?.message || `OTP was sent to ${email}. Check your email.`,
            isSuccess: true,
          }),
        );
        navigate(ROUTE_FORGOT_PW_SUBMIT_OTP, { state: { email } });
      })
      .catch(({ response }) => {
        dispatch(
          setAppAlert({
            message: response?.data?.error || 'Something went wrong. Please try again later.',
            isSuccess: false,
          }),
        );
        setEmail('');
      })
      .finally(() => setLoading(false));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      submitEmail();
    }
  };

  return (
    <div className="wrap">
      <div className="form_wrap">
        <span className="form_title">Submit email to request OTP.</span>
        <div className="form">
          <div>
            <label className="label" htmlFor="email">
              Personal email
            </label>
            <br />
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              onKeyDown={handleKeyPress}
              style={{ color: '#111', outline: 'none' }}
            />
          </div>
          <div className="checkbox">
            Login using password? <a href={sign}>Login</a>
          </div>
          <button
            className="submit_btn"
            style={{
              background: email && !loading ? '#00FEB9' : '#ccc',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={submitEmail}
            type="button"
            disabled={loading}
          >
            {loading ? <Loader size={20} /> : 'Get Code'}
          </button>
        </div>
        <div className="bg_wrap">
          <BgSmall className="bg_small" />
          <BgLarge />
        </div>
      </div>
    </div>
  );
};

export default RequestRtp;
