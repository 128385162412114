import { styled } from 'styled-components';

export const StyledLoggedInLinks = styled.div`
  margin: 0 5vw 0 auto;
  display: flex;
  align-items: center;
  gap: 5vw;

  .link {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    margin-left: 4vw;
  }

  @media only screen and (max-width: 991px) {
    .link {
      display: none;
    }
  }
`;

export const StyledLoggedInIcons = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const StyledLogoWrap = styled.span`
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

export const StyledCount = styled.span`
  display: inline-block;
  top: -8.5px;
  padding: 0px 5px;
  background: red;
  position: absolute;
  right: -12px;
  border-radius: 35%;
  color: #fff;
  text-align: center;
  font-size: 12px;
`;

export const StyledToggleWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const StyledToggleSlider = styled.div<{ $isOn: boolean }>`
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 15px;
  background: ${({ $isOn }) =>
    $isOn ? 'linear-gradient(270deg, #00feb9 0%, #00fafd 100%)' : '#ccc'};
  transition: background-color 0.4s;
`;

export const StyledToggleCircle = styled.div<{ $isOn: boolean }>`
  position: absolute;
  top: 2.5px;
  left: ${({ $isOn }) => ($isOn ? 'calc(100% - 24px)' : '3px')};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: left 0.4s;
  z-index: 20;
`;
