import { Link } from 'react-router-dom';

import Button from '../../../components/button';

import { getSign } from '../../../constants';

const StartToday = ({ user }) => {
  const sign = getSign();

  return (
    <div className="pt-5 mt-5 start-style">
      <div className="start-title-style">Start earning today</div>
      <div className="my-5 pt-5 ms-5 ps-5 join-button">
        <Link to={user.id ? '/market' : `${sign}&action=signup`} style={{ textDecoration: 'none' }}>
          <Button text="Join us now" />
        </Link>
      </div>
    </div>
  );
};

export default StartToday;
