import React from 'react';

import VerificationWrap from 'src/components/VerificationWrap/VerificationWrap';

import { StyledButton, StyledInputEmail, StyledInputEmailWrap } from '../EnterEmail/styled';

const SecurityVerification = () => {
  return (
    <VerificationWrap>
      <div>
        <div style={{ fontSize: 'calc(18px + 2vw)', color: '#111', paddingBottom: '15px' }}>
          Security verification
        </div>
        <div style={{ paddingLeft: '20px' }}>
          <div style={{ color: '#000000', lineHeight: '22px', paddingBottom: '20px' }}>
            Enter the 6-digit code sent to 88****89127749873
          </div>
          <div style={{ color: '#4E4E4E', paddingBottom: '10px' }}>
            Phone number verification code
          </div>
          <StyledInputEmailWrap>
            <StyledInputEmail />
            <br />
            <span
              style={{
                cursor: 'pointer',
                color: '#4E4E4E',
              }}
            >
              Resend code
            </span>
          </StyledInputEmailWrap>
          <br />
          <StyledButton>Submit</StyledButton>
        </div>
      </div>
    </VerificationWrap>
  );
};

export default SecurityVerification;
