export type BalanceProps = {
  name: string;
  balance: string;
  subname?: string;
};
const Balance = ({ name, balance, subname }: BalanceProps) => {
  return (
    <div className="d-flex flex-column">
      <div className="withdraw-balance-content" style={{ display: 'flex', gap: '6px' }}>
        <span style={{ color: 'gray' }}>{name}:</span>
        <span>{balance}</span>
        <span style={{ color: 'gray' }}>{subname}</span>
      </div>
    </div>
  );
};

export default Balance;
