import React, { ReactNode, FC } from 'react';

import Loader from 'src/components/Loader/Loader';

import { StyledContinueButton } from './styled';

interface IContent {
  title: string;
  subtitle?: string;
  buttonText: string;
  children: ReactNode;
  isNextActive?: boolean;
  submitLoading?: boolean;
  step?: number;
  handleNext: () => void;
}

const Content: FC<IContent> = ({
  title,
  subtitle,
  buttonText,
  isNextActive,
  submitLoading,
  children,
  handleNext,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <h2>{title}</h2>
      {subtitle && <p>{subtitle}</p>}
      {children}
      <StyledContinueButton
        onClick={handleNext}
        $isNextActive={!!isNextActive}
        disabled={submitLoading}
      >
        {submitLoading ? <Loader size={26} /> : buttonText}
      </StyledContinueButton>
    </div>
  );
};

export default Content;
