import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { StyledLoaderSpinner } from 'src/pages/Market/components/styled';

const Loader: FC<{ size?: number }> = ({ size }) => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  return <StyledLoaderSpinner size={size} $isDark={!!isDark} />;
};

export default Loader;
