import React from 'react';

import Dropdown from 'src/components/Dropdown';

const SortOption = ({ label, onClick }) => (
  <li>
    <button className="dropdown-item" onClick={onClick}>
      {label}
    </button>
  </li>
);

const SortDropdown = ({ setSort }) => (
  <div className="market-sort-btn dropdown px-2">
    <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">
      Sort by
    </button>
    <Dropdown>
      <SortOption label="Name" onClick={() => setSort('name')} />
      <SortOption label="Price" onClick={() => setSort('price')} />
      <SortOption label="24h Change" onClick={() => setSort('change')} />
    </Dropdown>
  </div>
);

export default SortDropdown;
