import React from 'react';

const Projects = () => {
  return (
    <div>
      <div style={{ height: '80px' }}></div>
      <div
        style={{
          padding: '25px 0',
          textAlign: 'center',
          fontSize: '22px',
          fontWeight: 800,
          background: '#eee',
        }}
      >
        Upcoming project <br /> in the One Piece Fanclub ecosystem:
      </div>
      <div style={{ height: '40px' }}></div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div>
          <div
            style={{
              paddingBottom: '10px',
              fontWeight: 600,
              fontSize: '20px',
              color: '#111',
              textAlign: 'center',
            }}
          >
            Vegapunk
          </div>
          <div style={{ fontWeight: 600, color: 'gray' }}>Release in October 2023</div>
        </div>
        <div>
          <div
            style={{
              paddingBottom: '10px',
              fontWeight: 600,
              fontSize: '20px',
              color: '#111',
              textAlign: 'center',
            }}
          >
            Smoker
          </div>
          <div style={{ fontWeight: 600, color: 'gray' }}>Release in November 2023</div>
        </div>
        <div>
          <div
            style={{
              paddingBottom: '10px',
              fontWeight: 600,
              fontSize: '20px',
              color: '#111',
              textAlign: 'center',
            }}
          >
            Sabo
          </div>
          <div style={{ fontWeight: 600, color: 'gray' }}>Release in December 2023</div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
