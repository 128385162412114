import React from 'react';
import { Search } from 'react-bootstrap-icons';

import { useSelector } from 'react-redux';

import SearchBox from '../../../components/search-box';
import MobileSearch from './MobileSearch';

const MarketHeader = ({ keyword, setKeyword }) => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  return (
    <div className="d-flex justify-content-between align-items-center my-5">
      <p className="market-title h1 mb-0">Market</p>
      <div className={`d-flex align-items-center market-search-box ${isDark ? 'isDark' : ''}`}>
        <SearchBox placeHolder="Search coin" keyword={keyword} setKeyword={setKeyword} />
      </div>
      <button
        id="button-addon4"
        type="button"
        className="mobile-search-icon d-none btn btn-link text-info"
        data-bs-toggle="modal"
        data-bs-target="#mobileSearchInput"
      >
        <Search />
      </button>
      <MobileSearch setKeyword={setKeyword} />
    </div>
  );
};

export default MarketHeader;
