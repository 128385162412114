import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { ReactComponent as ArrowDown } from 'src/assets/ArrowDown.svg';
import { ReactComponent as EyeIcon } from 'src/assets/Eye.svg';

import Text from 'src/components/Text/Text';
import ButtonsRow from './ButtonsRow';
import NoResult from 'src/components/NoResult/NoResult';
import Loader from 'src/components/Loader/Loader';
import Pagination from 'src/components/Pagination';
import Modal from 'src/pages/Orders/components/Modal';
import Badge from 'src/components/StatusBadge/Badge';

import { ROUTE_DASHBOARD, ROUTE_TRANSACTIONS, ROUTE_TRANSACTIONS_FIAT } from 'src/routes';
import { convertDate } from 'src/constants';

import { StyledItem, StyledRow, StyledTable } from '../styled';

interface ITable {
  title: string;
  buttons: { id: number; text: string; hasDropDown: boolean; caption?: string }[];
  tableHeaders: string[];
  columns: string;
  hasDateBtn?: boolean;
  hasSearch?: boolean;
  hasAsideBtns?: boolean;
  isTransaction?: boolean;
  transactions?: any;
  selectedType?: string;
  setSelectedType?: any;
  currentPage?: number;
  setCurrentPage?: any;
  totalPages?: number;
  loading?: boolean;
}

const Table: FC<ITable> = ({
  title,
  buttons,
  tableHeaders,
  columns,
  hasDateBtn,
  hasAsideBtns,
  isTransaction,
  transactions,
  selectedType,
  setSelectedType,
  currentPage,
  setCurrentPage,
  totalPages,
  loading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    created_at: string;
    crypto_api_transaction_request_id: string;
    from: string;
    to: string;
    recipient_address: string;
    quantity: string;
    status: string;
    updated_at: string;
    kind: string;
    currency: string;
  } | null>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isDark } = useSelector(({ isDark }) => isDark);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <>
      <StyledTable $isTransaction={!!isTransaction}>
        <div
          style={{ cursor: 'pointer', marginBottom: '20px' }}
          onClick={() => navigate(ROUTE_DASHBOARD)}
        >
          <span>
            <ArrowDown height={8} style={{ transform: 'rotate(90deg)' }} />
          </span>
          <span style={{ textDecoration: 'underline', paddingLeft: '10px' }}>Back</span>
        </div>
        {!isTransaction && (
          <Text size={18} color="#1B224A" padding="0 0 1.75vh 2.5vw">
            Spot
          </Text>
        )}
        <br />
        <Text size={22} color="#1B224A" weight={600} padding="0 0 3vh 2.5vw">
          {title}
        </Text>
        {isTransaction && (
          <div className="transactions_nav_btns">
            <button
              className={pathname === ROUTE_TRANSACTIONS ? 'active' : ''}
              onClick={() => navigate(ROUTE_TRANSACTIONS)}
            >
              Crypto
            </button>
            <button
              className={
                pathname === `${ROUTE_TRANSACTIONS}${ROUTE_TRANSACTIONS_FIAT}` ? 'active' : ''
              }
              onClick={() => navigate(`${ROUTE_TRANSACTIONS}${ROUTE_TRANSACTIONS_FIAT}`)}
            >
              Fiat
            </button>
          </div>
        )}
        <ButtonsRow
          hasAsideBtns={!!hasAsideBtns}
          hasDateBtn={hasDateBtn}
          hasSearch={pathname === ROUTE_TRANSACTIONS}
          buttons={buttons}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
        {loading ? (
          <Loader size={100} />
        ) : transactions && transactions.length ? (
          <>
            <div className="desktop_view" style={{ paddingBottom: '30px' }}>
              <StyledRow $columns={columns} $isHeader>
                {tableHeaders.map((header) => (
                  <span key={header}>{header}</span>
                ))}
              </StyledRow>
              <StyledRow
                $columns="0.75fr 0.75fr 0.75fr 1.25fr 1fr .6fr .6fr"
                style={{ padding: '0.6vw 2vw' }}
              >
                {!!transactions &&
                  transactions.map((item) => (
                    <>
                      <span key={v4()} style={{ padding: '8px 0' }}>
                        <span>{convertDate(item.created_at).date}</span>
                        <br />
                        <span>{convertDate(item.created_at).time}</span>
                      </span>
                      <StyledItem key={v4()}>{item.currency}</StyledItem>
                      <StyledItem key={v4()}>{item.kind}</StyledItem>
                      <StyledItem key={v4()}>{item.to || item.from}</StyledItem>
                      <StyledItem key={v4()}>{item.quantity}</StyledItem>
                      <StyledItem key={v4()}>
                        <Badge value={item.status} />
                      </StyledItem>
                      <StyledItem key={v4()}>
                        <span onClick={() => openModal(item)} style={{ cursor: 'pointer' }}>
                          <EyeIcon fill={isDark ? '#fff' : '#111'} />
                        </span>
                      </StyledItem>
                    </>
                  ))}
              </StyledRow>
              {transactions && transactions.length ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Pagination
                    currentPage={currentPage as number}
                    setCurrentPage={setCurrentPage}
                    nPages={totalPages as number}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <NoResult />
        )}
        <div className="mobile_view">
          <Text size={18} weight={700} color="#111" padding="0 0 .4vh 2vw">
            DOT/USDT
          </Text>
          <StyledRow $columns={columns} $isHeader>
            {tableHeaders.map((header) => (
              <span key={header}>{header}</span>
            ))}
          </StyledRow>
          <Text size={18} weight={700} color="#111" padding="0 0 .4vh 2vw">
            SOL/USDT
          </Text>
          <StyledRow $columns={columns} $isHeader>
            {tableHeaders.map((header) => (
              <span key={header}>{header}</span>
            ))}
          </StyledRow>
        </div>
      </StyledTable>
      <Modal isOpen={isModalOpen} onClose={closeModal} isDark={isDark}>
        {selectedItem && (
          <div>
            {selectedItem.id && (
              <p>
                <strong>ID:</strong> {selectedItem.id}
              </p>
            )}
            {selectedItem.crypto_api_transaction_request_id && (
              <p>
                <strong>Crypto API transaction request ID:</strong>{' '}
                {selectedItem.crypto_api_transaction_request_id}
              </p>
            )}
            <p>
              <strong>Created At:</strong> {convertDate(selectedItem.created_at).date}{' '}
              {convertDate(selectedItem.created_at).time}
            </p>
            <p>
              <strong>Update At:</strong> {convertDate(selectedItem.updated_at).date}{' '}
              {convertDate(selectedItem.updated_at).time}
            </p>
            {selectedItem.from && (
              <p>
                <strong>From:</strong> {selectedItem.from}
              </p>
            )}
            {selectedItem.to && (
              <p>
                <strong>To:</strong> {selectedItem.to}
              </p>
            )}
            {selectedItem.quantity && (
              <p>
                <strong>Quantity:</strong> {selectedItem.quantity}
              </p>
            )}
            {selectedItem.status && (
              <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <strong>Status:</strong> <Badge value={selectedItem.status} />
              </p>
            )}
            {selectedItem.recipient_address && (
              <p>
                <strong>Recipient address:</strong> {selectedItem.recipient_address}
              </p>
            )}
            {selectedItem.kind && (
              <p>
                <strong>Kind:</strong> {selectedItem.kind}
              </p>
            )}
            {selectedItem.currency && (
              <p>
                <strong>Currency:</strong> {selectedItem.currency}
              </p>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default Table;
