import styled, { css } from 'styled-components';

export const StyledBtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1vw;
`;

export const StyledTable = styled.div<{ $isTransaction: boolean }>`
  width: -webkit-fill-available;
  min-height: 83vh;
  border-radius: 20px;
  padding: 2% ${({ $isTransaction }) => ($isTransaction ? '3%' : '1%')};

  ${({ $isTransaction }) =>
    !$isTransaction &&
    css`
      -webkit-box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
    `}
  .transactions_nav_btns {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 0 4vh 4vw;

    & > button {
      border: 1.5px solid #00feb9;
      background: none;
      cursor: pointer;
      border-radius: 30px;
      padding: .2vw 4vw;
      transition: .4s;
    }

    & > button:hover {
      border: none;
      background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
    }

    .active {
      border: none;
      background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
    }
  }

  & > .mobile_view {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    height: auto;

    & > .desktop_view {
      display: none;
    }

    & > .mobile_view {
      display: block;

      & > div {
        grid-template-columns: auto;
        margin-bottom: 20px;

        & > span {
          padding: 5px 10px
        }
      }
    }
`;

export const StyledOrdersWrap = styled.div`
  height: 100%;
  display: flex;
  padding-right: 3vw;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding-right: 0;

    & > aside {
      height: auto;
      width: calc(100% + 24px);
    }

    & > aside > ul > li > a {
      display: flex;
      justify-content: center;
    }
  }
`;

export const StyledItem = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledButtonsRow = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 0 3vw 3vw;

  .caption_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 600px) {
    padding-left: 0;
    flex-direction: column;
  }
`;

export const StyledDateButton = styled.button`
  display: flex;
  align-items: center;
  align-self: baseline;
  gap: 1vw;
  border: 1px solid #cfcfcf;
  border-radius: 16px;
  background: none;
  color: rgba(23, 42, 79, 0.69);
  font-weight: 500;
  padding: 0.75vh 1vw;

  @media only screen and (max-width: 600px) {
    width: -webkit-fill-available;
    justify-content: center;
  }

  & > svg {
    transform: rotate(270deg);
  }
`;

export const StyledWrap = styled.div<{ margin?: string }>`
  display: flex;
  flex-wrap: ${({ margin }) => (margin ? 'nowrap' : 'wrap')};
  gap: 2vw;
  margin: ${({ margin }) => margin || 0};
  align-items: self-end;

  .input_search {
    padding: 0.5vw 0.75vw;
    border-radius: 30px;
    border: 1px solid #cfcfcf;
    outline: none;
    align-self: flex-end;
  }

  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
`;

export const StyledRow = styled.div<{ $columns: string; $isHeader?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $columns }) => $columns};
  background: ${({ $isHeader }) => ($isHeader ? '#F2F2F2' : 'none')};
  font-weight: ${({ $isHeader }) => ($isHeader ? '500' : '400')};
  padding: ${({ $isHeader }) => ($isHeader ? '.6vw 2vw' : '0')};
  border-radius: ${({ $isHeader }) => ($isHeader ? '20px' : '0')};
`;

export const StyledButton = styled.button<{
  width?: number;
  gap?: number;
  $hasDropDown?: boolean;
  label: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => `${gap || 2.5}vw`};
  border: 1px solid #cfcfcf;
  border-radius: 16px;
  padding: 0.75vh 0.75vw;
  width: ${({ width }) => (width ? `${width}vw` : 'auto')};
  background: none;
  color: ${({ label }) => (label ? 'rgba(23, 42, 79, 0.69)' : '#001131')};
  font-weight: 500;
  align-self: baseline;
  width: ${({ $hasDropDown }) => ($hasDropDown ? 'auto' : '100%')};
  text-align: ${({ $hasDropDown }) => ($hasDropDown ? 'auto' : 'center')};

  .label {
    color: #172a4f;
  }

  .dropdown_wrap {
    display: flex;
    align-items: center;
    gap: 0.5vw;
  }

  @media only screen and (max-width: 600px) {
    flex-grow: 1;
    padding: 0.75vh 2vw;
  }

  @media only screen and (max-width: 600px) {
    justify-content: space-between;
  }
`;
