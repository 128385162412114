import { Link } from 'react-router-dom';
import { ChevronDown } from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image';

import { useSelector } from 'react-redux';

import Button from '../../../components/button';

import { getSign } from 'src/constants';

const Main = () => {
  const sign = getSign();

  const { user } = useSelector(({ user }) => user);
  const { isDark } = useSelector(({ isDark }) => isDark);

  return (
    <div className="main-padding justify-content-between" style={{ display: 'flex' }}>
      <div
        className="jumbotron-title col-lg-6 col-sm-12"
        style={{ background: isDark ? '#111' : '#fff' }}
      >
        <div className="main-letter">Buy and exchange cryptocurrency with trust.</div>
        <div className="d-flex button-position">
          <Link
            to={user.id ? '/market' : `${sign}&action=signup`}
            style={{ textDecoration: 'none' }}
          >
            <Button text="Get Started" />
          </Link>
          <a href={'/#portfolio'} style={{ textDecoration: 'none' }}>
            <button className="empty-button d-flex justify-content-center align-items-center">
              How it works?
            </button>
          </a>
        </div>
      </div>
      <div className="main-picture col-lg-5 col-sm-12">
        <Image src="./assets/jumbotron-cropped-img.png" width="72%" className="d-block d-lg-none" />
        <Image
          src="./assets/jumbotron-img-cropped.png"
          width="100%"
          className="d-none d-lg-block"
        />
      </div>
      <div
        className="scroll-down-btn d-flex d-lg-none justify-content-center mb-5"
        style={{
          position: 'fixed',
          right: 0,
          visibility: 'hidden',
        }}
      >
        <ChevronDown width="20px" height="20px" />
      </div>
    </div>
  );
};

export default Main;
