import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 30vw;
  min-width: 400px;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3% 2%;
  max-height: 90%;
  overflow: auto;

  * {
    color: #111 !important;
  }

  @media only screen and (max-width: 600px) {
    width: 80vw;
    min-width: auto;
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 8px 14px;
  border-radius: 20px;
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledLabel = styled.label`
  padding: 5px 20px;
  background: #172a4f;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
`;

export const StyledClosebtn = styled.button`
  background: none;
  border: none;
  font-size: 36px;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
`;

export const StyledContinueButton = styled.button<{ $isNextActive: boolean }>`
  background: ${({ $isNextActive }) => ($isNextActive ? '#00feb9' : 'ccc')};
  border-radius: 20px;
  border: none;
  outline: none;
  color: ${({ $isNextActive }) => ($isNextActive ? '#fff' : '#172A4F')};
  padding: 8px;
  width: 100%;
  margin-top: 30px;
  pointer-events: ${({ $isNextActive }) => ($isNextActive ? 'auto' : 'none')};
`;

export const StyledInputContent = styled.input`
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 8px 15px;
  outline: none;
  color: #111 !important;
`;

export const StyledSquare = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 10rem;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #ccc;
`;

export const StyledOption = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  color: #111 !important;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const StyledUploadInput = styled.input`
  display: none;
`;

export const StyledUploadButton = styled.label`
  display: flex;
  align-items: center;
  gap: 3px;

  & > svg {
    cursor: pointer;
  }
`;

export const StyledButton = styled.button<{ $isOtp?: boolean }>`
  background: ${({ $isOtp }) => ($isOtp ? '#00FEB9' : '#ccc')};
  color: #fff;
  cursor: ${({ $isOtp }) => ($isOtp ? 'pointer' : 'auto')};
  border: none;
  padding: 6px 18px;
  border-radius: 40px;
`;

export const StyledCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  cursor: pointer;
`;

export const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.span<{ checked?: boolean }>`
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.checked ? '#333' : 'transparent')};
  border-radius: 4px;
  color: white;
  font-size: 14px;
  cursor: pointer;
`;

export const StyledCheckboxLabel = styled.span`
  user-select: none;
`;
