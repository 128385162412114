import React from 'react';
import Main from './components/Main';
import './index.css';

const Signup = () => {
  return (
    <div className="container-fluid">
      <Main />
    </div>
  );
};

export default Signup;
