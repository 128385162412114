import { FC, ReactElement, useState } from 'react';

import { ITabProps } from './Tab/Tab';

import { StyledTabs, StyledListItem, StyledTabsWrap } from './styled';

const Tabs: FC<{
  children: ReactElement<ITabProps>[];
  activeOrdersLength?: number;
  isLoggedIn: boolean;
  isKycVerified: boolean;
}> = ({ children, activeOrdersLength, isLoggedIn, isKycVerified }) => {
  const [activeTab, setActiveTab] = useState(`Open orders ${activeOrdersLength}`);

  return (
    <StyledTabsWrap className={!isLoggedIn || !isKycVerified ? 'blur-box' : ''}>
      <StyledTabs>
        {children.map(({ props: { label } }) => {
          const isActive = activeTab === label;
          return (
            <StyledListItem key={label} $hasBorder={isActive} onClick={() => setActiveTab(label)}>
              {label}
            </StyledListItem>
          );
        })}
      </StyledTabs>
      <>{children.map(({ props: { label, children } }) => label === activeTab && children)}</>
    </StyledTabsWrap>
  );
};

export default Tabs;
