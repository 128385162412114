import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchCurrencyPairs } from 'src/store/slice/currencyPairs';
import { fetchOrders, updateFilterPair, updateFilterType } from 'src/store/slice/orders';

import RenderButtons from './RenderButtons';
import SearchBox from 'src/components/search-box';
import Loader from 'src/components/Loader/Loader';

import { getOrdersType } from 'src/constants';

import { StyledButtonsRow, StyledWrap } from '../styled';
import { StyledButton } from './styled';

interface IButtonsRow {
  buttons: any;
  hasAsideBtns?: boolean;
  loading?: boolean;
  keyword: string;
  // eslint-disable-next-line no-unused-vars
  handleApplyFilters?: (pair: string, type: string) => void;
  setKeyword: any;
}

const ButtonsRow: FC<IButtonsRow> = ({
  buttons,
  hasAsideBtns,
  loading,
  keyword,
  setKeyword,
  handleApplyFilters,
}) => {
  const { pathname } = useLocation();

  const ORDERS_TYPE = getOrdersType(pathname);

  const dispatch = useDispatch();

  const { pairs } = useSelector(({ pairs }) => pairs);
  const { orders } = useSelector(({ orders }) => orders);
  const { isDark } = useSelector(({ isDark }) => isDark);

  const isDisabled =
    ((!orders[ORDERS_TYPE].filterType || orders[ORDERS_TYPE].filterType === 'Type') &&
      !orders[ORDERS_TYPE].filterPair) ||
    orders[ORDERS_TYPE].filterPair === 'Pair' ||
    loading;

  useEffect(() => {
    if (!pairs.length) {
      dispatch(fetchCurrencyPairs());
    }
  }, [dispatch, pairs.length]);

  return (
    <StyledButtonsRow>
      <StyledWrap>
        <RenderButtons
          buttons={buttons}
          pair={orders[ORDERS_TYPE].filterPair || 'Pair'}
          type={orders[ORDERS_TYPE].filterType || 'Type'}
          pairData={pairs}
        />
      </StyledWrap>
      {hasAsideBtns && (
        <StyledWrap $margin="0 0 0 auto" className={isDark ? 'buttons-row-search-wrap isDark' : ''}>
          <SearchBox placeHolder="Search" keyword={keyword} setKeyword={setKeyword} />
          <StyledButton
            type="button"
            onClick={() => {
              dispatch(
                fetchOrders({
                  type: ORDERS_TYPE,
                  page: 1,
                }),
              );
              dispatch(
                updateFilterType({
                  orderType: ORDERS_TYPE,
                  filterType: 'Type',
                }),
              );
              dispatch(
                updateFilterPair({
                  orderType: ORDERS_TYPE,
                  filterType: 'Pair',
                }),
              );
            }}
            disabled={isDisabled}
            className={`${isDark ? 'isDark' : 'null'}`}
          >
            {loading ? <Loader size={15} /> : 'Reset'}
          </StyledButton>
        </StyledWrap>
      )}
      <StyledButton
        type="button"
        onClick={() =>
          handleApplyFilters &&
          handleApplyFilters(orders[ORDERS_TYPE].filterType, orders[ORDERS_TYPE].filterPair)
        }
        disabled={isDisabled}
        className={`${isDark ? 'isDark' : 'null'}`}
      >
        {loading ? <Loader size={15} /> : 'Apply'}
      </StyledButton>
    </StyledButtonsRow>
  );
};

export default ButtonsRow;
