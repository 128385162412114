import React from 'react';

import { StyledCheckbox, StyledCheckboxContainer, StyledHiddenCheckbox } from './styled';

const Checkbox = ({ isChecked, setIsChecked, children }) => {
  return (
    <StyledCheckboxContainer>
      <StyledHiddenCheckbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
      <StyledCheckbox checked={isChecked}>{isChecked && <span>&#10003;</span>}</StyledCheckbox>
      {children}
    </StyledCheckboxContainer>
  );
};

export default Checkbox;
