import styled from 'styled-components';

export const StyledTabs = styled.ul`
  display: flex;
  gap: 6vw;
  margin-bottom: 15px;
  padding-left: 10px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }

  & > li {
    font-weight: 500;
    list-style: none;
    padding: 14px 0;
  }
`;

export const StyledListItem = styled.li<{ $hasBorder: boolean }>`
  cursor: pointer;
  color: ${({ $hasBorder }) => `${$hasBorder ? '#172A4F' : 'rgba(23, 42, 79, 0.69)'}`};
`;

export const StyledTabsWrap = styled.div`
  padding: 1.5vw;
  margin-top: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 500px) {
    box-shadow: none;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const StyledRowWrap = styled.div`
  padding: 1vw 0 1vw 10px;
  align-items: center;
  border-bottom: 1px solid #b4b4b4;
  display: grid;
  grid-template-columns: 0.7fr 0.9fr 0.6fr 0.4fr 0.55fr 0.8fr 0.7fr;

  @media screen and (max-width: 768px) {
    font-size: 10px;
    grid-template-columns: 0.7fr 0.9fr 0.7fr 0.5fr 0.65fr 0.8fr 0.5fr;
  }
`;

export const StyledOptionsModal = styled.div`
  width: 200px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 10;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 60px;
  right: 0;
`;
