import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useDispatch } from 'src/store/useDispatch';

import Balance from './components/Balance';
import Table from './components/Table';
import Dropdown from 'src/components/Dropdown';
import { fetchTickers } from 'src/store/slice/tickers';

import {
  ROUTE_ACCOUNT_CREATED,
  ROUTE_DEPOSIT,
  ROUTE_TRANSACTIONS,
  ROUTE_WITHDRAW,
} from 'src/routes';
import { KYC_STATUS, WALLET_BUTTON_TITLES } from 'src/constants';

import './index.css';
import Loader from 'src/components/Loader/Loader';

const Wallet = () => {
  const navigate = useNavigate();
  const [btcUsd, setBtcUsd] = useState(1);
  const [fiatUsdBalance, setFiatUsd] = useState(0.01);
  const [cryptoUsdBalance, setCryptoUsd] = useState(0.01);
  const [fiatBtcBalance, setFiatBtc] = useState(0.01);
  const [cryptoBtcBalance, setCryptoBtc] = useState(0.01);
  const [isBalanceVisible, setBalanceVisible] = useState(true);

  const dispatch = useDispatch();

  const {
    user: { email_verified, twoFA_verified, kyc_status },
  } = useSelector(({ user }) => user);
  const { isDark } = useSelector(({ isDark }) => isDark);
  const { tickers } = useSelector(({ tickers }) => tickers);
  const { walletAddresses, walletsCount, loading } = useSelector(
    ({ walletAddresses }) => walletAddresses,
  );

  const handleVerificationBeforeNavigate = (path: string) => {
    const url =
      email_verified && twoFA_verified && kyc_status === KYC_STATUS.VERIFIED
        ? path
        : ROUTE_ACCOUNT_CREATED;
    const state =
      email_verified && twoFA_verified && kyc_status === KYC_STATUS.VERIFIED
        ? null
        : { message: 'Verify your email, kyc and 2FA to use deposit and withdrawal service' };
    navigate(url, { state });

    // navigate(path);
  };

  const handleNaviage = (item: string) => {
    switch (item) {
      case 'Deposit':
        handleVerificationBeforeNavigate(ROUTE_DEPOSIT);
        break;
      case 'Withdraw':
        handleVerificationBeforeNavigate(ROUTE_WITHDRAW);
        break;
      case 'Send':
        navigate('/send/btc');
        break;
      case 'Transfer':
        navigate('/transfer/btc');
        break;
      case 'Transaction History':
        navigate(ROUTE_TRANSACTIONS);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setFiatBtc(fiatUsdBalance / btcUsd);
    setCryptoBtc(cryptoUsdBalance / btcUsd);
  }, [fiatUsdBalance, cryptoUsdBalance, btcUsd]);

  useEffect(() => {
    if (!tickers.length) {
      dispatch(fetchTickers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid px-lg-5 px-3" style={{ minHeight: '83vh' }}>
      <div className="d-flex flex-md-row flex-column justify-content-between align-items-center wallet-main-part my-5">
        <div className="d-flex flex-column wallet-main-title">
          <div className="d-flex justify-content-between align-items-center">
            <div className="h2 fw-bold wallet-fiat-spot mb-0">Fiat and Spot Balance</div>
            <button
              className="eye-button ms-1"
              style={{ background: 'transparent' }}
              onClick={() => setBalanceVisible(!isBalanceVisible)}
            >
              {isBalanceVisible ? (
                <AiFillEyeInvisible fontSize={20} />
              ) : (
                <AiFillEye fontSize={20} />
              )}
            </button>
          </div>
          <div className="d-flex flex-column mt-3 ms-md-5 ms-0">
            <div className="h5 fw-bold">Estimated Balance</div>
            {loading ? (
              <Loader size={24} />
            ) : (
              <div className="d-flex">
                <div className="h5">
                  {isBalanceVisible
                    ? `${(fiatBtcBalance + cryptoBtcBalance).toFixed(4)} BTC`
                    : '**************'}
                </div>
                <div className="h5 wallet-usd-balance">
                  {`~ ${
                    isBalanceVisible
                      ? `$${(fiatUsdBalance + cryptoUsdBalance).toFixed(4)}`
                      : '***************'
                  }`}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column align-items-md-start align-items-center mt-md-0 mt-5">
          <div className="d-xxl-flex d-none wallet-action-btn-group">
            {WALLET_BUTTON_TITLES.map((item, index) => (
              <button
                key={index}
                className={`ms-2 wallet-filter-btn ${isDark ? 'isDark' : ''}`}
                onClick={() => handleNaviage(item)}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="d-xxl-none d-block dropdown px-2">
            <button
              type="button"
              className="btn wallet-action-dropdown-btn dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Deposit
            </button>
            <Dropdown>
              {WALLET_BUTTON_TITLES.map((item, index) => (
                <li key={index}>
                  <button
                    className={`dropdown-item ${isDark ? 'isDark' : ''}`}
                    onClick={() => handleNaviage(item)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </Dropdown>
          </div>
          <div className="d-flex flex-md-row flex-column mt-4 ms-2">
            <Balance
              label={'Spot'}
              btcValue={cryptoBtcBalance.toFixed(4)}
              usdValue={cryptoUsdBalance.toFixed(4)}
              isVisible={isBalanceVisible}
              loading={loading}
            />
            <Balance
              label={'Fiat'}
              btcValue={fiatBtcBalance.toFixed(4)}
              usdValue={fiatUsdBalance.toFixed(4)}
              isVisible={isBalanceVisible}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <Table
        setCryptoUsd={setCryptoUsd}
        setFiatUsd={setFiatUsd}
        setBtcUsd={setBtcUsd}
        isVisible={isBalanceVisible}
        tickers={tickers}
        walletAddresses={walletAddresses}
        walletsCount={walletsCount}
        loading={loading}
      />
      <div style={{ height: 20 }}></div>
    </div>
  );
};

export default Wallet;
