import { ChangeEvent, FC } from 'react';

import { StyledUploadButton, StyledUploadInput } from './styled';

const UploadFile: FC<{ file?: File | null; setFile?: any; text?: string; isPlus?: boolean }> = ({
  text,
  isPlus,
  file,
  setFile,
}) => {
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile);
    }
  };

  const handleDelete = () => {
    setFile(null);
    const input = document.getElementById('upload-input') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  };

  return (
    <>
      {file ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '2px solid #ccc',
            padding: '8px',
            borderRadius: '20px',
          }}
        >
          <div style={{ marginRight: '8px' }}>{file.name}</div>
          <div style={{ cursor: 'pointer', transform: 'rotate(45deg)' }} onClick={handleDelete}>
            +
          </div>
        </div>
      ) : (
        <>
          <StyledUploadButton htmlFor={`upload-input-${text}`}>
            <div
              style={{
                border: '2px solid #ccc',
                padding: `8px ${isPlus ? '18px' : '8px'}`,
                borderRadius: '20px',
                cursor: 'pointer',
              }}
            >
              {isPlus ? '+' : `Upload ${text} Side`}
            </div>
          </StyledUploadButton>
          <StyledUploadInput
            id={`upload-input-${text}`}
            type="file"
            onChange={handleUpload}
            accept="image/*"
          />
        </>
      )}
    </>
  );
};

export default UploadFile;
