import React from 'react';

import Dropdown from 'src/components/Dropdown';

const FilterDropdown = ({ filterButtons, setFilter }) => (
  <div className="market-filter-dropdown-btn dropdown px-2">
    <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">
      Filter
    </button>
    <Dropdown>
      {filterButtons.map((filterButton, index) => (
        <li key={index}>
          <button
            className="dropdown-item"
            onClick={() => {
              setFilter(filterButton);
            }}
          >
            {filterButton}
          </button>
        </li>
      ))}
    </Dropdown>
  </div>
);

export default FilterDropdown;
