import React from 'react';

import { ReactComponent as Sun } from '../../../assets/Sun.svg';
import { ReactComponent as Moon } from '../../../assets/Moon.svg';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { setTheme } from 'src/store/slice/theme';

import { StyledToggleCircle, StyledToggleSlider, StyledToggleWrapper } from './styled';

const ThemeToggle = () => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  const dispatch = useDispatch();

  return (
    <StyledToggleWrapper onClick={() => dispatch(setTheme())}>
      <StyledToggleSlider $isOn={isDark}>
        {isDark ? (
          <Sun style={{ position: 'absolute', top: '4px', left: '3px', zIndex: 10 }} />
        ) : null}
        <StyledToggleCircle $isOn={isDark} />
        {!isDark ? (
          <Moon style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 10 }} />
        ) : null}
      </StyledToggleSlider>
    </StyledToggleWrapper>
  );
};

export default ThemeToggle;
