import React, { Component } from 'react';

class ManualTransaction extends Component {
  render() {
    return (
      <div>
        <div>
          <h2>Simplex to be implemented here</h2>
        </div>
      </div>
    );
  }
}

export default ManualTransaction;
