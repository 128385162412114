import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';

import VerificationWrap from 'src/components/VerificationWrap/VerificationWrap';

import { StyledButton } from './styled';

const EnterEmail = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(({ user }) => user);

  const handleCode = () => {
    setLoading(true);
    request
      .post(`${PUBLIC_URL}/v1/users/${user.id}/email/verification/resend`, null)
      .then(() => {
        dispatch(
          setAppAlert({
            message: 'The verification link has been sent to your email.',
            isSuccess: true,
          }),
        );
        navigate('/account-created');
      })
      .catch(({ response }) =>
        dispatch(
          setAppAlert({
            message: response?.data?.error || 'Something went wrong.',
            isSuccess: false,
          }),
        ),
      )
      .finally(() => setLoading(false));
  };

  return (
    <VerificationWrap>
      <div>
        <div style={{ fontSize: 'calc(18px + 2vw)', paddingBottom: '20px' }}>
          Email Verification
        </div>
        <div style={{ paddingLeft: '20px' }}>
          <div style={{ lineHeight: '22px', paddingBottom: '30px' }}>
            Verification link will be sent to your email <br /> by clicking the button below.
          </div>
          <StyledButton $isActive={!loading} disabled={loading} onClick={handleCode}>
            Send verification link
          </StyledButton>
          <br />
        </div>
      </div>
    </VerificationWrap>
  );
};

export default EnterEmail;
