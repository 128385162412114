import Image from 'react-bootstrap/esm/Image';

import { useSelector } from 'react-redux';

const Footer = () => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  return (
    <footer
      className={`footer-style mt-5 ${isDark ? 'isDark' : ''}`}
      style={{ marginBottom: '-2vh' }}
    >
      <div className="container pt-5 pb-5" style={{ display: 'flex' }}>
        <a className="col-md-3 d-flex align-items-start ps-5" href="#">
          <Image src="./assets/logo.png" width="100%" />
        </a>
        <div className="col-md-3 d-flex flex-column">
          {/* <a className="footer-title mb-1" href="/about-us">
            About Us
          </a> */}
          <a href="/about-us" className="footer-content mt-1">
            About Us
          </a>
          <a className="footer-title mb-1" href="/term-of-use">
            Terms of Use
          </a>
          <a href="/term-of-use" className="footer-content mt-1">
            Terms of Use
          </a>
          <a href="/privacy-policy" className="footer-title mb-1">
            Privacy Policy
          </a>
          <a href="/privacy-policy" className="footer-content mt-1">
            Privacy Policy
          </a>
          <a href="#" className="footer-content mt-1">
            Announcement
          </a>

          {/* <a href="#" className="footer-content mt-1">
            Team
          </a> */}
        </div>
        <div className="col-md-3 d-flex flex-column">
          <a className="footer-title mb-1" href="#">
            Support
          </a>
          {/* <a href="#" className="footer-content mt-1">
            Fees
          </a>
          <a href="#" className="footer-content mt-1">
            Trading Rules
          </a> */}
          <a href="/faq" className="footer-content mt-1">
            FAQ
          </a>
          <a href="#" className="footer-content mt-1">
            Submit a Ticket
          </a>
          {/* <a href="#" className="footer-content mt-1">
            Affiliate Program
          </a> */}
          <a
            href="https://medium.com/@bxlend"
            className="footer-content mt-1"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
        </div>
        <div className="col-md-3 d-flex flex-column">
          <a className="footer-title mb-1" href="#">
            Community
          </a>
          {/* <a href="#" className="footer-content mt-1">
            Discord
          </a> */}
          <a
            href="https://t.me/bxlend"
            target="_blank"
            rel="noreferrer"
            className="footer-content mt-1"
          >
            Telegram
          </a>
          {/* <a href="#" className="footer-content mt-1">
            Facebook
          </a> */}
          <a
            href="https://twitter.com/bxlend_"
            target="_blank"
            rel="noreferrer"
            className="footer-content mt-1"
          >
            Twitter
          </a>
          {/* <a href="#" className="footer-content mt-1">
            Reddit
          </a> */}
          <a href="https://bxlend.gitbook.io/bxlend-whitepaper" className="footer-content mt-1">
            Our Token
          </a>
        </div>
      </div>
      <div className="container d-flex flex-column align-items-center">
        <div className="footer-border mb-4 w-1"></div>
        <div className="copy-right-style pb-3">
          Copyright © 2024 BxLend. finance All Rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
