import React, { FC, ReactNode } from 'react';

import BgWrap from './BgWrap';

import { StyledVerificationWrap } from './styled';

const VerificationWrap: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <StyledVerificationWrap>
      {children}
      <BgWrap />
    </StyledVerificationWrap>
  );
};

export default VerificationWrap;
