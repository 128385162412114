import React, { useEffect, useState } from 'react';

import NoResult from 'src/components/NoResult/NoResult';

import { getSign } from '../../../constants';
import { ITradeData } from '../../../interfaces';
import { useSelector } from 'react-redux';

export type ITradeStatusProps = {
  myTradeData: ITradeData[];
};

const MyTrade = ({ myTradeData }: ITradeStatusProps) => {
  const [tradeData, setTradeData] = useState<ITradeData[]>(myTradeData);
  const [tradeDataType, setTradeDataType] = useState('market');
  const [averagePrice, setAverage] = useState(0);
  const accessToken = localStorage.getItem('access');
  const sign = getSign();

  const { isDark } = useSelector(({ isDark }) => isDark);

  const urlParams = new URLSearchParams(window.location.search);
  const symbolFromURL = urlParams.get('pair');
  const symbol = symbolFromURL ? symbolFromURL : 'btc-usdt';
  const currencies = symbol.split('-');
  const currency1 = currencies[0];
  const currency2 = currencies[1];

  const getAveragePrice = (data: ITradeData[]) => {
    let averageResult = 0;
    data.forEach((item) => {
      averageResult += parseFloat(item.price);
    });
    return averageResult / myTradeData.length;
  };

  useEffect(() => {
    if (tradeDataType === 'market') {
      setTradeData(myTradeData);
      setAverage(getAveragePrice(myTradeData));
    } else {
      setTradeData([]);
      setAverage(getAveragePrice([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTradeData, tradeDataType]);

  return (
    <div className="position-relative">
      <div className="d-flex flex-column my-trade">
        <div className="d-flex align-items-end">
          <button
            className={`h1 me-5 ${
              tradeDataType === 'market' ? 'my-trade-title' : 'my-trade-subtitle'
            }`}
            style={{
              color: isDark ? '#fff' : '#172A4F',
              background: isDark ? 'transparent' : 'auto',
            }}
            onClick={() => setTradeDataType('market')}
          >
            Market Trades
          </button>
          <button
            className={`h1 ${
              tradeDataType === 'my trade' ? 'my-trade-title' : 'my-trade-subtitle'
            }`}
            style={{
              color: isDark ? '#fff' : '#172A4F',
              background: isDark ? 'transparent' : 'auto',
            }}
            onClick={() => setTradeDataType('my trade')}
          >
            My Trades
          </button>
        </div>
        <table
          className={`table table-borderless ${
            !accessToken && tradeDataType === 'my trade' ? 'blur-box' : ''
          }`}
        >
          <thead>
            <tr className="row mt-4 mb-3">
              <th className="col-4 justify-content-start align-items-center d-flex">{`Price(${currency2.toUpperCase()})`}</th>
              <th className="col-5 justify-content-center align-items-center d-flex">
                {`Amount(${currency1.toUpperCase()})`}
              </th>
              <th className="col-3 justify-content-end align-items-center d-flex pe-2">Total</th>
            </tr>
          </thead>
          <tbody>
            {tradeData.length ? (
              tradeData.map((item, index) => {
                return (
                  <tr className="row" key={index}>
                    <td
                      className={`col-5 justify-content-start align-items-center d-flex ${
                        parseFloat(item.price) >= averagePrice
                          ? 'increased-item-price'
                          : 'decreased-item-price'
                      }`}
                    >
                      {item.price}
                    </td>
                    <td className="col-4 justify-content-start align-items-center d-flex">
                      {item.amount}
                    </td>
                    <td className="col-3 justify-content-end align-items-center d-flex">
                      {(parseFloat(item.price) * parseFloat(item.amount)).toFixed(4)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>
                  <NoResult styles={{ paddingTop: '100px' }} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!accessToken && tradeDataType === 'my trade' && (
        <div className="position-absolute blur-text">
          Please <a href={sign}>Login</a> to view order book
        </div>
      )}
    </div>
  );
};

export default MyTrade;
