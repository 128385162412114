import React from 'react';

import FilterButton from './FilterButton';

const FilterButtons = ({ filterButtons, filter, setFilter }) => (
  <div className="d-flex market-filter-btn-group">
    {filterButtons.map((filterButton, index) => (
      <FilterButton key={index} filterButton={filterButton} setFilter={setFilter} filter={filter} />
    ))}
  </div>
);

export default FilterButtons;
