import React from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'src/store/useDispatch';
import { updateFilterPair, updateFilterType } from 'src/store/slice/orders';
import { useSelector } from 'react-redux';

import Button from './Button';

import { getOrdersType } from 'src/constants';

import { StyledSelect } from '../styled';

const RenderButtons = ({ buttons, pair, type, pairData }) => {
  const sortedPairs = [...pairData].sort((a, b) => a.symbol.localeCompare(b.symbol));

  const { isDark } = useSelector(({ isDark }) => isDark);

  const { pathname } = useLocation();

  const ORDERS_TYPE = getOrdersType(pathname);

  const dispatch = useDispatch();

  return buttons.map(({ text, hasDropDown, gap }) => {
    if (text === 'Type') {
      return (
        <StyledSelect
          key={text}
          value={type}
          onChange={(e) => {
            const value = e.currentTarget.value;
            dispatch(
              updateFilterType({
                orderType: ORDERS_TYPE,
                filterType: value,
              }),
            );
          }}
          className={isDark ? 'isDark' : ''}
        >
          <option value="Type">Type</option>
          <option value="BUY">BUY</option>
          <option value="SELL">SELL</option>
        </StyledSelect>
      );
    } else if (text === 'Pair') {
      return (
        <StyledSelect
          key={text}
          value={pair}
          onChange={(e) => {
            const value = e.currentTarget.value;
            dispatch(
              updateFilterPair({
                orderType: ORDERS_TYPE,
                filterPair: value === 'Pair' ? '' : value,
              }),
            );
          }}
          className={isDark ? 'isDark' : ''}
        >
          <option value="Pair">Pair</option>
          {sortedPairs.map(({ symbol }) => (
            <option key={symbol}>{symbol}</option>
          ))}
        </StyledSelect>
      );
    } else {
      return <Button key={text} text={text} hasDropDown={hasDropDown} gap={gap} />;
    }
  });
};

export default RenderButtons;
