import { useState, FC } from 'react';
import { IoMailOpen } from 'react-icons/io5';
import { IoMdMail } from 'react-icons/io';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchNotificationsCount } from 'src/store/slice/notificationsCount';

import { ReactComponent as Avatar } from '../../../assets/Avatar.svg';

import Loader from 'src/components/Loader/Loader';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';

import '../index.css';

export type ItemProps = {
  message: string;
  createdAt: string;
  read: boolean;
  id: string;
};

const getRelativeTime = (createdAt: string): string => {
  const currentTime = new Date();
  const createdTime = new Date(createdAt);
  const timeDifference = currentTime.getTime() - createdTime.getTime();
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const weeksDifference = Math.floor(daysDifference / 7);
  const monthsDifference = Math.floor(daysDifference / 30);
  const yearsDifference = Math.floor(daysDifference / 365);

  if (secondsDifference < 60) {
    return secondsDifference === 1 ? '1 second ago' : `${secondsDifference} seconds ago`;
  } else if (minutesDifference < 60) {
    return minutesDifference === 1 ? '1 minute ago' : `${minutesDifference} minutes ago`;
  } else if (hoursDifference < 24) {
    return hoursDifference === 1 ? '1 hour ago' : `${hoursDifference} hours ago`;
  } else if (daysDifference < 7) {
    return daysDifference === 1 ? '1 day ago' : `${daysDifference} days ago`;
  } else if (weeksDifference < 4) {
    return weeksDifference === 1 ? '1 week ago' : `${weeksDifference} weeks ago`;
  } else if (monthsDifference < 12) {
    return monthsDifference === 1 ? '1 month ago' : `${monthsDifference} months ago`;
  } else {
    return yearsDifference === 1 ? '1 year ago' : `${yearsDifference} years ago`;
  }
};

const NotificationItem: FC<ItemProps> = ({ message, createdAt, read, id }) => {
  const [isRead, setIsRead] = useState(read);
  const [loading, setLoading] = useState(false);
  const relativeTime = getRelativeTime(createdAt);

  const dispatch = useDispatch();

  const { isDark } = useSelector(({ isDark }) => isDark);

  const handleReadStatus = () => {
    if (isRead) return null;
    setLoading(true);
    request
      .put(`${PUBLIC_URL}/v1/notifications/${id}`, {})
      .then(() => {
        setIsRead(true);
        dispatch(fetchNotificationsCount());
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className={`notification-container w-100 py-3 px-5 d-flex justify-content-between align-items-center ${
        isDark ? 'isDark' : ''
      }`}
      onClick={handleReadStatus}
      style={{ cursor: isRead ? 'auto' : 'pointer' }}
    >
      <div className="d-flex" style={{ alignItems: 'center', gap: '20px' }}>
        <div style={{ minWidth: '30px' }}>
          <div>
            <Avatar fill="#172A4F" />
          </div>
        </div>
        <div style={{ paddingRight: 20 }}>
          <div className={`notification-content ${isDark ? 'isDark' : ''}`}>{message}</div>
          <div className={`notification-time ${isDark ? 'isDark' : ''}`}>{relativeTime}</div>
        </div>
      </div>
      <div className="notification-read">
        {loading ? <Loader size={26} /> : isRead ? <IoMailOpen /> : <IoMdMail />}
      </div>
    </div>
  );
};

export default NotificationItem;
