import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useDispatch } from '../../store/useDispatch';
import { fetchUser } from 'src/store/slice/user';
import { setAppAlert } from 'src/store/slice/appAlert';
import { fetchNotificationsCount } from 'src/store/slice/notificationsCount';

import VerificationWrap from 'src/components/VerificationWrap/VerificationWrap';
import VerificationPopUp from './VerificationPopUp/VerificationPopUp';
import QrPopUp from './VerificationPopUp/QrPopUp';
import Loader from 'src/components/Loader/Loader';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';
import { KYC_STATUS, VERIFY_ACCOUNT_MESSAGES, getColor, getDocTypeConverted } from 'src/constants';
import { ROUTE_ENTER_EMAIL, ROUTE_ENTER_PHONE } from 'src/routes';

import { StyledButton } from '../EnterEmail/styled';
import { StyledContainer, StyledOvalShape } from './styled';

const AccountCreated = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isQrPopupOpen, setIsQrPopupOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [docType, setDocType] = useState('');
  const [documentQuery, setDocumentQuery] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [pinCode, setPinCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [privacyAgreed, setPrivacyAgreed] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<null | File>(null);
  const [frontImage, setFrontImage] = useState<null | File>(null);
  const [backImage, setBackImage] = useState<null | File>(null);
  const [isKYCdone, setIsKYCDone] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    user: { email_verified, phone_number_verified, twoFA_verified, kyc_status, id },
    loading,
  } = useSelector(({ user }) => user);
  const { countries } = useSelector(({ countries }) => countries);
  const { isDark } = useSelector(({ isDark }) => isDark);

  const navigate = useNavigate();

  const { state, pathname } = useLocation();

  const { code } = countries.find((c: { name: string; code: string }) => c.name === query) ?? {
    code: '',
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const formData = new FormData();
      const type = docType === 'Passport' ? docType.toUpperCase() : getDocTypeConverted(docType);

      if (uploadedImage) formData.append('kyc_document', uploadedImage);
      if (frontImage) formData.append('kyc_document', frontImage);
      if (backImage) formData.append('kyc_document', backImage);
      formData.append('name[first]', firstName);
      formData.append('name[last]', lastName);
      formData.append('country_code', code);
      formData.append('address[city]', city);
      formData.append('address[pin_code]', pinCode);
      formData.append('address[full_address]', address);
      formData.append('terms_and_conditions_consent', termsAgreed.toString());
      formData.append('privacy_policy_consent', privacyAgreed.toString());
      formData.append('identification_type', type);

      await request
        .post(`${PUBLIC_URL}/v1/kyc/create-request`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        })
        .then(() => {
          setIsKYCDone(true);
          dispatch(
            setAppAlert({
              message: 'You have successfully submitted you KYC data.',
              isSuccess: true,
            }),
          );
        })
        .catch(({ response }) =>
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Something went wrong.',
              isSuccess: false,
            }),
          ),
        )
        .finally(() => {
          setSubmitLoading(true);
          setIsPopupOpen(false);
        });
    } catch (error) {
      console.error('An error occurred while submitting the form:', error);
    }
  };

  useEffect(() => {
    dispatch(fetchUser);
  }, [isKYCdone, dispatch]);

  useEffect(() => {
    if (state && state.message) {
      dispatch(setAppAlert({ message: state.message, isSuccess: false }));
      navigate(pathname, { replace: true });
    }
    dispatch(fetchNotificationsCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerificationWrap>
      {loading ? (
        <Loader size={100} />
      ) : (
        <div>
          <div
            style={{
              fontSize: 'calc(18px + 1vw)',
              color: isDark ? '#fff' : '#172A4F',
              padding: '10px 0',
            }}
          >
            Account created
          </div>
          <div>
            <div
              style={{ color: isDark ? '#fff' : '#111', lineHeight: '22px', paddingBottom: '30px' }}
            >
              Verify your identification to enjoy your <br /> BxLend journey.
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px', minWidth: '332px' }}
            >
              <StyledContainer
                onClick={() => navigate(ROUTE_ENTER_EMAIL)}
                $isVerified={!!email_verified}
              >
                <StyledOvalShape deg="-5" color={email_verified ? '#20BF55' : '#828282'} />
                <div>
                  <div style={{ color: isDark ? '#111' : '#4E4E4E' }}>Verify Email</div>
                  <div style={{ color: isDark ? '#111' : '#828282' }}>
                    {email_verified
                      ? 'You have already verified your email.'
                      : 'Please verify your email as the first step.'}
                  </div>
                </div>
              </StyledContainer>
              <StyledContainer onClick={() => navigate(ROUTE_ENTER_PHONE)}>
                <StyledOvalShape deg="10" color={phone_number_verified ? '#20BF55' : '#828282'} />
                <div>
                  <div style={{ color: isDark ? '#111' : '#4E4E4E' }}>Phone Number</div>
                  <div style={{ color: isDark ? '#111' : '#828282' }}>
                    {phone_number_verified
                      ? 'You have verified you phone number.'
                      : 'Please verify your phone number.'}
                  </div>
                </div>
              </StyledContainer>
              <StyledContainer
                onClick={() => setIsPopupOpen(true)}
                $isVerified={
                  kyc_status === KYC_STATUS.PENDING || kyc_status === KYC_STATUS.VERIFIED
                }
              >
                {loading ? (
                  <Loader size={46} />
                ) : (
                  <>
                    <StyledOvalShape deg="-5" color={getColor(kyc_status)} />
                    <div>
                      <div style={{ color: isDark ? '#111' : '#4E4E4E' }}>Account Verification</div>
                      <div style={{ color: isDark ? '#111' : '#828282' }}>
                        {VERIFY_ACCOUNT_MESSAGES[kyc_status] ??
                          'Verify your account in a few minutes.'}
                      </div>
                    </div>
                  </>
                )}
              </StyledContainer>
              <StyledContainer
                onClick={() => setIsQrPopupOpen(true)}
                $isVerified={!!twoFA_verified}
              >
                <StyledOvalShape deg="10" color={twoFA_verified ? '#20BF55' : 'red'} />
                <div>
                  <div style={{ color: isDark ? '#111' : '#4E4E4E' }}>2 factor verification</div>
                  <div style={{ color: isDark ? '#111' : '#828282' }}>
                    Add your 2 factor authentication.
                  </div>
                </div>
              </StyledContainer>
            </div>
            <br />
            <StyledButton>Verify now</StyledButton>
          </div>
        </div>
      )}
      {isPopupOpen && (
        <VerificationPopUp
          onClose={() => setIsPopupOpen(false)}
          firstName={firstName}
          setFirstName={setFirstName}
          address={address}
          backImage={backImage}
          city={city}
          countries={countries}
          docType={docType}
          documentQuery={documentQuery}
          frontImage={frontImage}
          handleSubmit={handleSubmit}
          lastName={lastName}
          middleName={middleName}
          pinCode={pinCode}
          privacyAgreed={privacyAgreed}
          query={query}
          setAddress={setAddress}
          setBackImage={setBackImage}
          setCity={setCity}
          setDocType={setDocType}
          setDocumentQuery={setDocumentQuery}
          setFrontImage={setFrontImage}
          setLastName={setLastName}
          setMiddleName={setMiddleName}
          setPinCode={setPinCode}
          setPrivacyAgreed={setPrivacyAgreed}
          setQuery={setQuery}
          setTermsAgreed={setTermsAgreed}
          setUploadedImage={setUploadedImage}
          termsAgreed={termsAgreed}
          uploadedImage={uploadedImage}
          submitLoading={submitLoading}
        />
      )}
      {isQrPopupOpen && <QrPopUp userId={id} onClose={() => setIsQrPopupOpen(false)} />}
    </VerificationWrap>
  );
};

export default AccountCreated;
