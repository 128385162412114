import { useEffect, useState } from 'react';

import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import NotificationItem from './components/NotificationItem';
import NoResult from 'src/components/NoResult/NoResult';
import Loader from 'src/components/Loader/Loader';
import Pagination from 'src/components/Pagination';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';

import './index.css';

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();

  const getNotifications = (page) => {
    setIsLoading(true);
    request
      .get(`${PUBLIC_URL}/v1/notifications?page=${page}&limit=10`)
      .then(({ data }) => {
        setNotifications(data?.notifications);
        setTotalPages(data?.meta?.page_count);
      })
      .catch(({ response }) =>
        dispatch(
          setAppAlert({
            message: response?.data?.error || 'Something went wrong.',
            isSuccess: false,
          }),
        ),
      )

      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getNotifications(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className="notifications-contrainer">
      {isLoading ? (
        <div className="loader-wrap">
          <Loader size={100} />
        </div>
      ) : (
        <div className="list-wrap">
          {notifications.map(({ id, message, created_at, readStatus }) => (
            <NotificationItem
              key={id}
              message={message}
              createdAt={created_at}
              read={readStatus}
              id={id}
            />
          ))}
        </div>
      )}
      {!isLoading && notifications.length ? (
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '30px' }}>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            nPages={totalPages}
          />
        </div>
      ) : null}
      {!notifications.length && !isLoading ? (
        <NoResult
          styles={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      {!notifications.length && !isLoading ? <div>There is no notifications.</div> : null}
    </div>
  );
};

export default NotificationsPage;
