import React from 'react';

import Logo from '../../assets/Logo.svg';

import { StyledBonusWrap } from './styled';

const BonusSection = () => {
  return (
    <StyledBonusWrap style={{ display: 'flex' }}>
      <div
        style={{
          padding: '2.5% 2.5%',
          flexGrow: 1,
          background:
            'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,26,121,1) 35%, rgba(0,212,255,1) 100%)',
          position: 'relative',
        }}
      >
        <span
          style={{
            padding: '4px 18px',
            border: '1px solid #fff',
            color: '#fff',
            fontWeight: 600,
            borderRadius: '50px',
          }}
        >
          Listing on Nov 10
        </span>
        <img
          src={Logo}
          alt="icon"
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -40%)',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
            paddingBottom: '50px',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#fff',
              fontWeight: 500,
              paddingBottom: '20px',
              borderBottom: '1px solid #fff',
              marginTop: 'auto',
            }}
          >
            2023-10-23 09:00 ~ 2023-10-29 09:00 (UTC+3)
          </div>
          <div
            style={{
              textAlign: 'center',
              color: '#fff',
              fontWeight: 800,
              padding: '10px 0',
            }}
          >
            10% Bonus : PROB
          </div>
          <div
            style={{
              textAlign: 'center',
              color: '#fff',
              fontWeight: 500,
            }}
          >
            5% Bonus : USDT, BTC, ETH, BNB
          </div>
        </div>
      </div>
      <div style={{ padding: '1.5% 2.5%', background: '#eee', maxWidth: '50%' }}>
        <ul style={{ display: 'flex' }}>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              padding: '1% 0',
              flexGrow: 1,
              textAlign: 'center',
              listStyle: 'none',
              cursor: 'pointer',
            }}
          >
            PROB
          </li>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              padding: '1% 0',
              flexGrow: 1,
              textAlign: 'center',
              listStyle: 'none',
              cursor: 'pointer',
            }}
          >
            USDT
          </li>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              padding: '1% 0',
              flexGrow: 1,
              textAlign: 'center',
              listStyle: 'none',
              cursor: 'pointer',
            }}
          >
            BTC
          </li>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              padding: '1% 0',
              flexGrow: 1,
              textAlign: 'center',
              listStyle: 'none',
              cursor: 'pointer',
            }}
          >
            ETH
          </li>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              padding: '1% 5%',
              listStyle: 'none',
              cursor: 'pointer',
            }}
          >
            BNB
          </li>
        </ul>
        <div style={{ position: 'relative', padding: '12px 0' }}>
          <span
            style={{
              position: 'absolute',
              border: '2px solid #ca1010',
              display: 'inline-block',
              borderRadius: '20px',
              padding: '0 12px',
              fontSize: '12px',
              color: '#ca1010',
              fontWeight: 600,
            }}
          >
            Bonus 10%
          </span>
          <div style={{ textAlign: 'right', paddingBottom: '6px' }}>
            <a href="#" style={{ fontWeight: 600 }}>
              Buy PROB
            </a>
          </div>
          <div style={{ textAlign: 'right' }}>
            Available Balance <b>0.00000000</b> PROB
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <input
            value={0}
            style={{
              padding: '1% 15%',
              width: '100%',
              border: '1px solid #ccc',
              textAlign: 'right',
              color: 'gray',
              fontWeight: 600,
            }}
          />
          <span
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
              left: '5%',
              color: 'gray',
              fontWeight: 600,
            }}
          >
            Payment |
          </span>
          <span
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
              right: '2%',
              color: 'gray',
              fontWeight: 600,
            }}
          >
            PROB
          </span>
        </div>
        <ul style={{ display: 'flex', marginTop: '6px' }}>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              listStyle: 'none',
              flexGrow: 1,
              textAlign: 'center',
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            25%
          </li>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              listStyle: 'none',
              flexGrow: 1,
              textAlign: 'center',
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            50%
          </li>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              listStyle: 'none',
              flexGrow: 1,
              textAlign: 'center',
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            75%
          </li>
          <li
            style={{
              border: '1px solid gray',
              color: 'gray',
              fontWeight: 600,
              listStyle: 'none',
              flexGrow: 1,
              textAlign: 'center',
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            100%
          </li>
        </ul>
        <div style={{ position: 'relative', marginTop: '8px' }}>
          <input
            value={0}
            style={{
              padding: '1% 15%',
              width: '100%',
              border: '1px solid #ccc',
              textAlign: 'right',
              color: 'gray',
              fontWeight: 600,
            }}
          />
          <span
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
              left: '5%',
              color: 'gray',
              fontWeight: 600,
            }}
          >
            You get
          </span>
          <span
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
              right: '2%',
              color: 'gray',
              fontWeight: 600,
            }}
          >
            ZRT
          </span>
        </div>
        <ul style={{ margin: '12px 0' }}>
          <li
            style={{
              listStyle: 'none',
              display: 'flex',
              gap: '10px',
              fontSize: '14px',
            }}
          >
            <input type="checkbox" />
            <span>
              I agree with the token purchasing terms. <a href="#">View terms</a>
            </span>
          </li>
          <li
            style={{
              listStyle: 'none',
              display: 'flex',
              gap: '10px',
              fontSize: '14px',
            }}
          >
            <input type="checkbox" />
            <span>
              I am not a citizen of one of the <a href="#">countries listed.</a>
            </span>
          </li>
          <li
            style={{
              listStyle: 'none',
              display: 'flex',
              gap: '10px',
              fontSize: '14px',
            }}
          >
            <input type="checkbox" />
            <span>
              I understand that purchased tokens will be distributed according to the{' '}
              <a href="#">vesting schedule.</a>
            </span>
          </li>
        </ul>
        <div
          style={{
            padding: '10px',
            textAlign: 'center',
            color: '#443f3f',
            fontSize: '20px',
            fontWeight: 600,
            background: '#9e9e9e',
            width: '100%',
          }}
        >
          End of sale
        </div>
        <div style={{ marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '14px' }}>Estimated value</span>
            <span>
              <b>0 USDT</b>
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '14px' }}>Price</span>
            <span>
              <b>0.00025</b>
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '14px' }}>Purchase amount</span>
            <span>
              <b>0 ZRT</b>
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '14px' }}>Bonus</span>
            <span>
              <b>0 ZRT</b>
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '14px' }}>Amount including bonus</span>
            <span>
              <b>0 ZRT</b>
            </span>
          </div>
        </div>
      </div>
    </StyledBonusWrap>
  );
};

export default BonusSection;
