import Image from 'react-bootstrap/esm/Image';

import './style.css';

export type IHelpComponent = {
  url: string;
  title: string;
};

function HelpComponent({ url, title }: IHelpComponent) {
  return (
    <div className="d-flex align-items-center flex-column pt-5">
      <Image className="help-content-img" src={url} width="200px" height="200px" />
      <div className="help-title mt-3">{title}</div>
    </div>
  );
}

export default HelpComponent;
