import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ChevronDown, ChevronLeft } from 'react-bootstrap-icons';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchCurrencies } from 'src/store/slice/currencies';
import { setAppAlert } from 'src/store/slice/appAlert';

import Balance from './components/Balance';
import WithdrawOtpPopUp from './components/WithdrawOtpPopUp';
import Dropdown from 'src/components/Dropdown';
import Loader from 'src/components/Loader/Loader';

import request from '../../request';
import { PUBLIC_URL } from '../../configs';
import { ROUTE_WALLET } from 'src/routes';

import './index.css';

const currenciesToShow = ['BTC', 'ETH', 'USDT'];

const Withdraw = () => {
  const [networks, setNetworks] = useState<string[]>(['TESTNET']);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [quantity, setQuantity] = useState('');
  const [decimals, setDecimals] = useState(8);
  const [availableBalance, setAvailableBalance] = useState('');
  const [name, setName] = useState('');
  const [fee, setFee] = useState('');

  const { coin } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currencies } = useSelector(({ currencies }) => currencies);

  const isData = !!(coin && address && quantity);

  const gasLimit = 29000;
  const withdrawalOverhead = 1.02;

  useEffect(() => {
    const fetchWalletAddress = (id: string) => {
      if (!id) {
        return null;
      }

      try {
        request
          .get(`${PUBLIC_URL}/v1/wallet_addresses/${id}`)
          .then(({ data }) => {
            setAvailableBalance(data.wallet.available_balance);
            setName(data.wallet.currency);
          })
          .catch(({ response }) => {
            navigate(ROUTE_WALLET);
            dispatch(
              setAppAlert({
                message: response?.data?.error || 'Something went wrong.',
                isSuccess: false,
              }),
            );
          });
      } catch (e) {
        console.log(e);
      }
    };

    if (!currencies.length) {
      dispatch(fetchCurrencies());
    }

    const activeCurrency = currencies.find((curr) => curr.code === coin);
    fetchWalletAddress(activeCurrency?.id);
  }, [coin, currencies, dispatch, navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchNetworks = () =>
    request
      .get(`${PUBLIC_URL}/v1/currencies/code/${coin}`)
      .then(({ data }) => {
        setNetworks(data.currency_code.networks);
        setDecimals(data.currency_code.decimals);
        return data.currency_code.networks[0];
      })
      .catch(({ response }) => {
        dispatch(
          setAppAlert({
            message: response?.data?.error || 'Something went wrong.',
            isSuccess: false,
          }),
        );
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchFees = (network: string) => {
    setLoading(true);
    try {
      request
        .get(
          `${PUBLIC_URL}/v1/transactions/withdraw/fee/recommendation?blockchain=${coin}&network=${network}`,
        )
        .then(({ data }) => {
          let feeInBaseUnit = parseFloat(data.fee.standard_fee);
          if (data.fee.unit === 'WEI') {
            feeInBaseUnit = feeInBaseUnit / 1e18;
            feeInBaseUnit = feeInBaseUnit * gasLimit;
          }
          feeInBaseUnit = feeInBaseUnit * withdrawalOverhead;
          setFee(feeInBaseUnit.toFixed(decimals));
        })
        .catch(({ response }) => {
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Something went wrong.',
              isSuccess: false,
            }),
          );
        })
        .finally(() => setLoading(false));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchNetworks().then((network) => fetchFees(network));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coin]);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
      <div className="container-fluid px-lg-5 px-sm-3 position-relative">
        <div className="d-flex align-items-center mt-5 mb-4">
          <a href="/wallet" className="btn">
            <ChevronLeft />
          </a>
          <div className="h2 m-0">Withdraw Crypto</div>
        </div>
        <div className="d-flex flex-md-row flex-column align-items-center justify-content-between p-sm-5 p-1 coin-wallet-info">
          <div className="col-md-8 col-12 d-flex flex-column coin-network-info">
            <div className="mb-4">
              <div className="coin-title">Select Coin</div>
              <div className="coin-list-dropdown dropdown my-2">
                <button
                  type="button"
                  className="btn coin-dropdown-btn dropdown-toggle px-4"
                  data-bs-toggle="dropdown"
                >
                  <div className="d-flex">
                    <div className="ms-2 d-flex">
                      <div className="fw-bold">{coin}</div>
                      <div className="ms-2">{coin}</div>
                    </div>
                  </div>
                  <ChevronDown />
                </button>
                <Dropdown classNames="w-100 drop-down">
                  {currencies
                    .filter((c) => currenciesToShow.includes(c.code))
                    .map((coin, index) => (
                      <Link key={index} to={`/withdraw/${coin.code}`} state={{ code: coin }}>
                        <li>
                          <button className="dropdown-item py-2">
                            <div className="d-flex">
                              <div className="ms-2 d-flex">
                                <div className="fw-bold" style={{ color: '#172A4F' }}>
                                  {coin.code}
                                </div>
                                <div className="ms-2" style={{ color: '#172A4F' }}>
                                  {coin.name}
                                </div>
                              </div>
                            </div>
                          </button>
                        </li>
                      </Link>
                    ))}
                </Dropdown>
              </div>
            </div>
            <div>
              <div className="coin-title">Send to Network</div>
              <div className="coin-list-dropdown dropdown my-2">
                <button
                  type="button"
                  className="btn coin-dropdown-btn dropdown-toggle px-4"
                  data-bs-toggle="dropdown"
                  disabled
                >
                  {!!networks && !!networks.length && (
                    <div className="d-flex">
                      <div className="fw-bold">{networks[0]}</div>
                      <div className="ms-2">{networks[0]}</div>
                    </div>
                  )}
                  <ChevronDown />
                </button>
                {!!networks && !!networks.length && (
                  <Dropdown classNames="w-100">
                    {networks.map((network, index) => (
                      <li key={index}>
                        <button className="dropdown-item">
                          <div className="ms-2 d-flex">
                            <div className="fw-bold">{network}</div>
                            <div className="ms-2">{network}</div>
                          </div>
                        </button>
                      </li>
                    ))}
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
          {loading ? (
            <Loader size={30} />
          ) : (
            <div className="col-md-4 col-12 d-flex flex-column wallet-address-info mt-sm-0 mt-5">
              {name ? (
                <>
                  <div className="d-flex flex-column mb-4">
                    <div className="coin-title mb-3">Address</div>
                    <input
                      type="text"
                      className="input-wallet-address p-3"
                      placeholder="Enter Address"
                      value={address}
                      style={{ color: '#111' }}
                      onChange={(e) => setAddress(e.currentTarget.value)}
                    />
                  </div>
                  <div className="d-flex flex-column mb-4">
                    <div className="coin-title mb-3">Quantity</div>
                    <input
                      className="input-wallet-address p-3"
                      placeholder="Enter Quantity"
                      value={quantity}
                      type="number"
                      style={{ color: '#111' }}
                      onChange={(e) => setQuantity(e.currentTarget.value)}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-4"
                    style={{ marginBottom: '30px' }}
                  >
                    <Balance balance={availableBalance} name={name} />
                    <Balance balance={Number(fee).toFixed(decimals)} name="Fee" subname={name} />
                  </div>
                  <button
                    style={{
                      borderRadius: '12px',
                      border: 'none',
                      padding: '5px 10px',
                      background:
                        !isData || loading
                          ? '#ccc'
                          : 'linear-gradient(270deg, #00feb9 0%, #00fafd 100%)',
                      color: '#fff',
                      pointerEvents: loading || !isData ? 'none' : 'auto',
                      height: '34px',
                    }}
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    {loading ? <Loader size={20} /> : 'Withdraw'}
                  </button>
                </>
              ) : null}
            </div>
          )}
        </div>
        <div className="d-flex deposit-extra-info my-5 flex-sm-row flex-column">
          <div className="col-md-6 col-sm-6 col-12 deposit-faq">
            <div className="h5 mb-3">FAQ</div>
            <ul className="ps-4">
              <li>
                <a href="#" className="btn btn-link deposit-faq-item">
                  Video Tutorial
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-link deposit-faq-item">
                  How to Deposit Crypto Step-by-step Guide
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-link deposit-faq-item">
                  Why has my deposit not been credited yet?
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-link deposit-faq-item">
                  How to buy crypto and get started on BxLend
                </a>
              </li>
              <li>
                <a href="#" className="btn btn-link deposit-faq-item">
                  Deposit & Withdrawal status query.
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isOpen && (
        <WithdrawOtpPopUp
          closeModal={() => setIsOpen(false)}
          onClose={() => {
            setIsOpen(false);
            setAddress('');
            setQuantity('');
          }}
          code={coin}
          address={address}
          quantity={quantity}
          fee={`${fee} ${coin}`}
        />
      )}
    </>
  );
};

export default Withdraw;
