const BreadCrumb = () => {
  return (
    <nav aria-label="breadcrumb" className="my-5">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Market
        </li>
      </ol>
    </nav>
  );
};

export default BreadCrumb;
