import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import { PUBLIC_URL } from 'src/configs';
import request from 'src/request';

import Tabs from './Tabs/Tabs';
import Tab from './Tabs/Tab/Tab';

import { StyledRowWrap } from './Tabs/styled';
import { HEADERS, getSign } from 'src/constants';
import Table from './Table';

const OrdersTabs = ({ ordersCount, isKycVerified }) => {
  const [activeOrders, setActiveOrders] = useState([]);
  const [fulfilledOrders, setFulfilledOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();

  const { isDark } = useSelector(({ isDark }) => isDark);

  const accessToken = localStorage.getItem('access');
  const sign = getSign();

  useEffect(() => {
    const fetchOrders = () => {
      setIsLoading(true);
      try {
        request
          .get(`${PUBLIC_URL}/v1/orders?page=${currentPage}&limit=10`)
          .then(({ data }) => {
            setActiveOrders(data.orders.filter(({ status }) => status === 'PENDING'));
            setFulfilledOrders(
              data.orders.filter(({ status }) => status === 'ACTIVE' || status === 'FULFILLED'),
            );
            setTotalPages(data?.meta?.page_count);
          })

          .catch(({ response }) =>
            dispatch(
              setAppAlert({
                message: response?.data?.error || 'Something went wrong',
                isSuccess: false,
              }),
            ),
          )
          .finally(() => setIsLoading(false));
      } catch (e) {
        console.log(e);
      }
    };

    if (accessToken) {
      fetchOrders();
    } else {
      dispatch(
        setAppAlert({
          message: 'You are logged out',
          isSuccess: false,
        }),
      );
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersCount, currentPage, accessToken, dispatch]);

  useEffect(() => {
    const access = localStorage?.getItem('access');
    setIsLoggedIn(!!access);
  }, []);

  return (
    <div className="position-relative">
      <Tabs
        activeOrdersLength={activeOrders.length}
        isLoggedIn={isLoggedIn}
        isKycVerified={isKycVerified}
      >
        <Tab label={`Open orders ${activeOrders.length}`}>
          <>
            <StyledRowWrap>
              {HEADERS.map((title) => (
                <span key={v4()} style={{ color: isDark ? '#fff' : '#172A4F', fontWeight: 500 }}>
                  {title}
                </span>
              ))}
            </StyledRowWrap>
            {isLoggedIn && isKycVerified ? (
              <Table
                data={activeOrders}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                loading={isLoading}
              />
            ) : (
              <div style={{ padding: '50px 0 30px', textAlign: 'center' }}>
                Please <a href={sign}>Login</a> to view Open orders.
              </div>
            )}
          </>
        </Tab>
        <Tab label="Order history" key={v4()}>
          <>
            <>
              <StyledRowWrap>
                {[...HEADERS]
                  .filter((_, index, array) => index !== array.length - 1)
                  .map((title) => (
                    <span
                      key={v4()}
                      style={{ color: isDark ? '#fff' : '#172A4F', fontWeight: 500 }}
                    >
                      {title}
                    </span>
                  ))}
              </StyledRowWrap>
            </>
            {isLoggedIn ? (
              <Table
                data={fulfilledOrders}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                loading={isLoading}
              />
            ) : (
              <div style={{ padding: '50px 0 30px', textAlign: 'center' }}>
                Please <a href={sign}>Login</a> to view Order history.
              </div>
            )}
          </>
        </Tab>
      </Tabs>
      {!isLoggedIn && (
        <div className="position-absolute blur-text">
          Please <a href={sign}>Login</a> to view Open orders.
        </div>
      )}
      {isLoggedIn && !isKycVerified && (
        <div className="position-absolute blur-text">
          Please verify the <a href="/account-created">KYC</a> to view open orders.
        </div>
      )}
    </div>
  );
};

export default OrdersTabs;
