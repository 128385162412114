import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { StyledPaginationButtonsWrap } from '../Loader/styled';

interface IPagination {
  currentPage: number;
  nPages: number;
  // eslint-disable-next-line no-unused-vars
  setCurrentPage: any;
}

const Pagination: FC<IPagination> = ({ currentPage, nPages, setCurrentPage }) => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  const maxPageButtonsToShow = 5;

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageButtons = () => {
    const pageButtons: (string | number)[] = [];
    if (nPages <= maxPageButtonsToShow) {
      for (let i = 1; i <= nPages; i++) {
        pageButtons.push(i);
      }
    } else {
      const halfMaxButtons = Math.floor(maxPageButtonsToShow / 2);
      const startPage = Math.max(1, currentPage - halfMaxButtons);
      const endPage = Math.min(nPages, currentPage + halfMaxButtons);

      if (startPage > 1) {
        pageButtons.push(1);
        if (startPage > 2) {
          pageButtons.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(i);
      }

      if (endPage < nPages) {
        if (endPage < nPages - 1) {
          pageButtons.push('...');
        }
        pageButtons.push(nPages);
      }
    }

    return pageButtons.map((page, index) => (
      <span
        key={index}
        onClick={() => handlePageClick(page as string)}
        style={{
          fontWeight: 600,
          cursor: 'pointer',
          background: currentPage === page ? '#172A4F' : '',
          padding: '6px 12px',
          borderRadius: '8px',
          color: isDark || currentPage === page ? '#fff' : '#172A4F',
        }}
      >
        {page}
      </span>
    ));
  };

  const handlePageClick = (page: string) => {
    if (page !== '...') {
      setCurrentPage(page);
    }
  };

  return (
    <StyledPaginationButtonsWrap>
      <button
        onClick={handlePrevClick}
        style={{
          border: 'none',
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
          fontSize: '24px',
          background: 'none',
        }}
        disabled={currentPage === 1}
      >
        {'<'}
      </button>
      {renderPageButtons()}
      <button
        onClick={handleNextClick}
        style={{
          border: 'none',
          cursor: currentPage === nPages ? 'not-allowed' : 'pointer',
          fontSize: '24px',
          background: 'none',
        }}
        disabled={currentPage === nPages}
      >
        {'>'}
      </button>
    </StyledPaginationButtonsWrap>
  );
};

export default Pagination;
