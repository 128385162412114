import React from 'react';

import BonusSection from './BonusSection';

import GraphicsList from './GraphicsList';
import Projects from './Projects';
import Cautions from './Cautions';
import Footer from 'src/pages/Home/components/Footer';

import LogoSpotlight from '../../assets/LogoSpotlight.jpg';
import Mission from '../../assets/Mission.jpg';
import Intro from '../../assets/Intro.jpg';
import IntroPartTwo from '../../assets/IntroPartTwo.jpg';
import CryptoEconomy from '../../assets/CryptoEconomy.jpg';
import Background from '../../assets/Background.jpg';
import SaleDetails from '../../assets/SaleDetails.jpg';
import TokenDestribution from '../../assets/TokenDestribution.jpg';
import Roadmap from '../../assets/Roadmap.jpg';
import Team from '../../assets/Team.jpg';
import TeamPartTwo from '../../assets/TeamPartTwo.jpg';
import TeamPartThree from '../../assets/TeamPartThree.jpg';

const Spotlight = () => {
  return (
    <>
      <div style={{ padding: '5% 10%' }}>
        <button
          type="button"
          style={{
            background: 'none',
            padding: '10px 20px',
            border: '1px solid #172A4F',
            marginBottom: '25px',
          }}
        >
          Return to list
        </button>
        <BonusSection />
        <div style={{ height: '20px' }}></div>
        <button style={{ border: 'none', padding: '20px', width: '100%', textAlign: 'center' }}>
          Log in to check ZRT amount
        </button>
        <div style={{ height: '75px' }}></div>
        <GraphicsList />
        <Projects />
        <Cautions />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '50px',
            paddingTop: '150px',
          }}
        >
          <img src={LogoSpotlight} style={{ width: '60%' }} />
          <img src={Mission} style={{ width: '60%' }} />
          <img src={Intro} style={{ width: '60%' }} />
          <img src={IntroPartTwo} style={{ width: '60%' }} />
          <img src={CryptoEconomy} style={{ width: '60%' }} />
          <img src={Background} style={{ width: '60%' }} />
          <img src={SaleDetails} style={{ width: '60%' }} />
          <img src={TokenDestribution} style={{ width: '60%' }} />
          <img src={Roadmap} style={{ width: '60%' }} />
          <img src={Team} style={{ width: '60%' }} />
          <img src={TeamPartTwo} style={{ width: '60%' }} />
          <img src={TeamPartThree} style={{ width: '60%' }} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Spotlight;
