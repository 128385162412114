import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const SelectedOption = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 40px;
  cursor: pointer;
  white-space: nowrap;
  background: #fff;
  color: #111 !important;
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff !important;
`;

const OptionItem = styled.li`
  color: #111 !important;
  padding: 10px;
  cursor: pointer;
  color: #111;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CustomSelect = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || options[0]);
  const selectRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // @ts-expect-error Note: Expected type issue
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SelectWrapper ref={selectRef}>
      <SelectedOption onClick={() => setIsOpen(!isOpen)}>
        +{selectedOption.phone_code} {selectedOption.code}
      </SelectedOption>
      {isOpen && (
        <OptionsList>
          {options.map((option) => (
            <OptionItem key={option.code} onClick={() => handleOptionClick(option)}>
              +{option.phone_code} {option.code}
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </SelectWrapper>
  );
};

export default CustomSelect;
