import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import { ReactComponent as BgSmall } from '../../assets/BgSmall.svg';
import { ReactComponent as BgLarge } from '../../assets/BgLarge.svg';
import { ReactComponent as EyeIcon } from '../../assets/Eye.svg';
import { ReactComponent as EyeClosedIcon } from '../../assets/EyeClosed.svg';

import Loader from 'src/components/Loader/Loader';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';
import { PASSWORD_VALIDATION_ERROR, getSign, validatePassword } from 'src/constants';

import './index.css';

const RequestRtp = () => {
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { state } = useLocation();

  const sign = getSign();

  const navigate = useNavigate();

  const redirectToLoginPage = () => {
    localStorage.removeItem('access');
    navigate(`${sign}&action=signup`);
    navigate(0);
  };

  const sumbitPassword = () => {
    setLoading(true);
    request
      .post(`${PUBLIC_URL}/auth/forgot-password/${state.email}`, { otp, password })
      .then(({ data }) => {
        dispatch(
          setAppAlert({
            message: data?.msg || 'Password has been reset.',
            isSuccess: true,
          }),
        );
        redirectToLoginPage();
      })
      .catch(({ response }) => {
        dispatch(
          setAppAlert({
            message: response?.data?.error || 'Something went wrong. Please try again later.',
            isSuccess: false,
          }),
        );
      })
      .finally(() => {
        setPassword('');
        setOtp('');
        setLoading(false);
      });
  };

  return (
    <div className="wrap">
      <div className="form_wrap">
        <span className="form_title">Enter OTP which was sent to your email.</span>
        <form>
          <div style={{ position: 'relative' }}>
            <label className="label" htmlFor="email">
              6 Digit OTP
            </label>
            <br />
            <input
              id="password"
              type={showOtp ? 'text' : 'password'}
              value={otp}
              onChange={(e) => setOtp(e.currentTarget.value)}
              style={{ color: '#111', outline: 'none' }}
            />
            {showOtp ? (
              <EyeClosedIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '39%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowOtp(!showOtp)}
              />
            ) : (
              <EyeIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '39%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowOtp(!showOtp)}
              />
            )}
          </div>
          <div style={{ position: 'relative' }}>
            <label className="label" htmlFor="password">
              New Password
            </label>
            <br />
            <input
              id="new_password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
                setIsPasswordValid(validatePassword(e.currentTarget.value));
              }}
              style={{ color: '#111', outline: 'none' }}
            />
            {showPassword ? (
              <EyeClosedIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '39%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeIcon
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '39%',
                  cursor: 'pointer',
                }}
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
          {!isPasswordValid && <p style={{ color: 'red' }}>{PASSWORD_VALIDATION_ERROR}</p>}
          <div className="login_label">
            Login using password? <a href={sign}>Login</a>
          </div>
          <button
            className="submit_btn"
            type="button"
            onClick={sumbitPassword}
            disabled={loading || !otp || !password || !isPasswordValid}
            style={{
              background: otp && password && isPasswordValid && !loading ? '#00FEB9' : '#ccc',
              cursor: otp && password && isPasswordValid ? 'pointer' : 'auto',
              pointerEvents: otp && password && isPasswordValid && !loading ? 'auto' : 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading ? <Loader size={20} /> : 'Submit'}
          </button>
        </form>
        <div className="bg_wrap">
          <BgSmall className="bg_small" />
          <BgLarge />
        </div>
      </div>
    </div>
  );
};

export default RequestRtp;
