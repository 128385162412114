import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledLoaderSpinner = styled.div<{ size?: number; $isDark: boolean }>`
  width: ${({ size }) => `${size || 14}px`};
  height: ${({ size }) => `${size || 14}px`};
  border: 1px solid ${({ $isDark }) => ($isDark ? '#fff' : '#172a4f')};
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin: 0 auto;
`;

export const StyledLoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;
