import { SecondTitle } from '../../../components/title';
import Summary from '../../../components/summary';
import Button from '../../../components/button';
import { getSign } from '../../../constants';
import { Link } from 'react-router-dom';

const Join = ({ user }) => {
  const sign = getSign();
  return (
    <div className="mt-5 pt-5">
      <SecondTitle text="BxLend is a crypto exchange for everyone." />
      <div className="d-flex justify-content-around mt-5 join-content">
        <Summary title="1M+" content="Verified users" />
        <Summary title="99+" content="Countries supported" />
        <Summary title="$99B+" content="Quarterly trading volume" />
      </div>
      <div className="mt-5 pt-5 ms-5 ps-5 join-button">
        <Link to={user.id ? '/market' : `${sign}&action=signup`} style={{ textDecoration: 'none' }}>
          <Button text="Join us now" />
        </Link>
      </div>
    </div>
  );
};

export default Join;
