import React, { FC } from 'react';
import { v4 } from 'uuid';

import Row from './Row';
import NoResult from 'src/components/NoResult/NoResult';
import Pagination from 'src/components/Pagination';
import Loader from 'src/components/Loader/Loader';

import { convertDate } from 'src/constants';

import { StyledRowWrap } from './Tabs/styled';

const Table: FC<{
  data?: any;
  currentPage: number;
  setCurrentPage: any;
  totalPages: number;
  loading: boolean;
}> = ({ data, currentPage, setCurrentPage, totalPages, loading }) => {
  const orders = data && data.length ? data : null;

  return (
    <div>
      {loading ? (
        <div style={{ padding: '10% 0 5%' }}>
          <Loader size={100} />
        </div>
      ) : (
        <>
          {orders && orders.length ? (
            orders.map(({ created_at, pair, direction, executed_price, quantity }) => (
              <StyledRowWrap key={v4()}>
                <Row
                  cells={{
                    date: convertDate(created_at),
                    pair: pair ?? 'Pair',
                    direction: direction ?? 'BUY',
                    price: executed_price ?? '0.00',
                    amount: quantity ?? '1.11',
                    total:
                      executed_price && quantity ? (executed_price * quantity).toString() : '15',
                  }}
                />
              </StyledRowWrap>
            ))
          ) : (
            <NoResult styles={{ padding: '10% 0 5%' }} />
          )}
          {orders && orders.length ? (
            <div style={{ paddingTop: '30px' }}>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                nPages={totalPages}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Table;
