import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import Loader from 'src/components/Loader/Loader';

import { PUBLIC_URL } from 'src/configs';
import request from 'src/request';
import { StyledSubmitButton, StyledInput, StyledWrap, StyledNextButton } from './styled';

const WithdrawOtpPopUp = ({ onClose, closeModal, code, address, quantity, fee }) => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAgreedToFee, setIsAgreedToFee] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const submitOtp = async () => {
    try {
      setLoading(true);
      request
        .post(`${PUBLIC_URL}/v1/transactions/withdraw`, {
          quantity,
          currency_code: code,
          recipient_address: address,
          two_fa_code: otp,
        })
        .then(({ data }) => {
          dispatch(
            setAppAlert({
              message: data?.msg || 'Operation has been carried out successfully.',
              isSuccess: true,
            }),
          );
        })
        .catch(({ response }) => {
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Something went wrong.',
              isSuccess: false,
            }),
          );
        })
        .finally(() => {
          onClose();
          setLoading(false);
        });
    } catch (error) {
      console.error('Error submitting OTP code:', error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.currentTarget.value;
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 6);

    setOtp(sanitizedValue);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, onClose, closeModal]);

  return (
    <>
      <StyledWrap ref={popupRef}>
        <h4 style={{ marginBottom: '30px' }}>
          {isAgreedToFee ? (
            'Enter Google 2FA Code from authenticator app'
          ) : (
            <span>
              {fee}{' '}
              <span style={{ fontWeight: 400, color: 'gray' }}>
                fee will be taken from this operation
              </span>
            </span>
          )}
        </h4>
        {isAgreedToFee ? (
          <StyledInput value={otp} maxLength={6} onChange={handleInputChange} />
        ) : null}
        <br />
        {isAgreedToFee ? (
          <StyledSubmitButton
            disabled={loading}
            style={{
              background:
                otp && !loading ? 'linear-gradient(270deg, #00feb9 0%, #00fafd 100%)' : '#ccc',
              pointerEvents: otp ? 'auto' : 'none',
            }}
            type="button"
            onClick={submitOtp}
          >
            {loading ? <Loader size={20} /> : 'Submit'}
          </StyledSubmitButton>
        ) : (
          <StyledNextButton disabled={loading} type="button" onClick={() => setIsAgreedToFee(true)}>
            Proceed
          </StyledNextButton>
        )}
      </StyledWrap>
    </>
  );
};

export default WithdrawOtpPopUp;
