import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import Text from 'src/components/Text/Text';
import ButtonsRow from './ButtonsRow';
import DisplayItems from './DisplayItems';
import Loader from 'src/components/Loader/Loader';
import Pagination from 'src/components/Pagination';

import { StyledRow, StyledTable } from '../styled';

interface IDataRow {
  created_at: string;
  direction: string;
  fee: { percentage: number; amount: string };
  id: string;
  owner: string;
  owner_type: string;
  pair: string;
  quantity: string;
  status: string;
  updated_at: string;
  wallets: string[];
}

interface ITable {
  title: string;
  buttons: any;
  tableHeaders: string[];
  columns: string;
  hasDateBtn?: boolean;
  hasAsideBtns?: boolean;
  data?: IDataRow[];
  currentPage: number;
  totalPages: number;
  setCurrentPage: any;
  // eslint-disable-next-line no-unused-vars
  handleApplyFilters?: (type: string, pair: string) => void;
  loading?: boolean;
  keyword: string;
  setKeyword: any;
}

const Table: FC<ITable> = ({
  title,
  buttons,
  tableHeaders,
  columns,
  currentPage,
  setCurrentPage,
  hasAsideBtns,
  totalPages,
  data = [],
  loading,
  keyword,
  setKeyword,
  handleApplyFilters,
}) => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  return (
    <StyledTable>
      <Text size={18} color="#1B224A" padding="0 0 1.75vh 2.5vw">
        Spot
      </Text>
      <br />
      <Text size={22} color="#1B224A" weight={600} padding="0 0 3vh 2.5vw">
        {title}
      </Text>
      <ButtonsRow
        hasAsideBtns={hasAsideBtns}
        buttons={buttons}
        handleApplyFilters={handleApplyFilters}
        loading={loading}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      <div className="desktop_view" style={{ minHeight: 'calc(100% - 15vh - 5%)' }}>
        <StyledRow
          $columns={columns}
          $isHeader
          className={`${isDark ? 'table-header isDark' : ''}`}
        >
          {tableHeaders.map((header) => (
            <span key={header}>{header}</span>
          ))}
        </StyledRow>
        {loading ? (
          <div style={{ paddingTop: '10vh' }}>
            <Loader size={80} />
          </div>
        ) : (
          <DisplayItems columns={columns} items={data ?? []} />
        )}
        {!loading && data && data.length ? (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            nPages={totalPages}
          />
        ) : null}
      </div>
      <div className="mobile_view">
        <Text size={18} weight={700} color="#111" padding="0 0 .4vh 2vw">
          DOT/USDT
        </Text>
        <StyledRow $columns={columns} $isHeader>
          {tableHeaders.map((header) => (
            <span key={header}>{header}</span>
          ))}
        </StyledRow>
        <Text size={18} weight={700} color="#111" padding="0 0 .4vh 2vw">
          SOL/USDT
        </Text>
        <StyledRow $columns={columns} $isHeader>
          {tableHeaders.map((header) => (
            <span key={header}>{header}</span>
          ))}
        </StyledRow>
      </div>
    </StyledTable>
  );
};

export default Table;
