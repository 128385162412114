import React from 'react';

const FAQ = () => {
  return (
    <div style={{ padding: ' 2.5% 20%' }}>
      <div style={{ marginBottom: '40px' }}>
        <div style={{ fontSize: '20px', fontWeight: 600 }}>What is a cryptocurrency exchange?</div>
        <div>
          Cryptocurrency exchanges are digital marketplaces that enable users to buy and sell
          cryptocurrencies like Bitcoin, Ethereum, and Tether.
        </div>
      </div>
      <div style={{ marginBottom: '40px' }}>
        <div style={{ fontSize: '20px', fontWeight: 600 }}>What products does BxLend provide?</div>
        <div>
          BxLend is the early stage cryptocurrency exchange, catering to a million registered users
          globally. With low fees and over 100+ cryptocurrencies to trade, BxLend is the preferred
          exchange to trade Bitcoin, Altcoins, and other virtual assets in Europe. With BxLend users
          can: Trade hundreds of cryptocurrencies in different markets. Earn interest on your crypto
          with Staking Options. Keep their assets in BxLend Wallet securely. Borrow and Lend assets
          via BxLend platform.
        </div>
      </div>
      <div style={{ marginBottom: '40px' }}>
        <div style={{ fontSize: '20px', fontWeight: 600 }}>
          How to buy Bitcoin and other cryptocurrencies on BxLend?
        </div>
        <div>
          There are several ways to buy cryptocurrencies on BxLend. You can use a credit/debit card
          or cash balance to purchase crypto on BxLend. Before getting started, please make sure
          you’ve completed Identity Verification for your BxLend account.
        </div>
      </div>
      <div style={{ marginBottom: '40px' }}>
        <div style={{ fontSize: '20px', fontWeight: 600 }}>How to track cryptocurrency prices?</div>
        <div>
          The easiest way to track the latest cryptocurrency prices, trading volumes, trending
          altcoins, and market cap is the BxLend Cryptocurrency Directory. Click on the coins to
          know historical coin prices, 24-hour trading volume, and the price of cryptocurrencies
          like Bitcoin, Ethereum, BXL and others in real-time.
        </div>
      </div>
      <div style={{ marginBottom: '40px' }}>
        <div style={{ fontSize: '20px', fontWeight: 600 }}>
          How to trade cryptocurrencies on BxLend?
        </div>
        <div>
          You can trade hundreds of cryptocurrencies on BxLend via the Spot, Margin, Futures, and
          Options markets. To begin trading, users need to register an account, complete identity
          verification, buy/deposit crypto, and start trading.
        </div>
      </div>
      <div style={{ marginBottom: '40px' }}>
        <div style={{ fontSize: '20px', fontWeight: 600 }}>How to earn from crypto on BxLend?</div>
        <div>
          Users can earn rewards on more than 100+ cryptocurrencies by using one of the products
          offered on BxLend Staking Platform. Our platform offers dozens of digital assets like
          Bitcoin, Ethereum, and stablecoins.
        </div>
      </div>
    </div>
  );
};

export default FAQ;
