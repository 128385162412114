import styled from 'styled-components';

export const StyledContainer = styled.div<{ $isVerified?: boolean }>`
  display: flex;
  gap: 1.5vw;
  padding: 10px 20px;
  background: #eee;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ $isVerified }) => ($isVerified ? 'none' : 'auto')};
  min-width: 332px;
`;

export const StyledOvalShape = styled.div<{ deg: string; color: string }>`
  width: 30px;
  height: 32px;
  transform: ${({ deg }) => `skewX(${deg}deg)`};
  background: ${({ color }) => color ?? '#828282'};
  border-radius: 50%;
`;
