import React from 'react';

const Cautions = () => {
  return (
    <div>
      <div style={{ height: '80px' }}></div>
      <h4 style={{ paddingBottom: '30px', borderBottom: '1px solid #cccc', textAlign: 'center' }}>
        Cautions
      </h4>
      <ul>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>
          Sale and purchase of ZRT take place between you and ZRT the Issuer and ProBit is neither a
          seller nor a party as any capacity in the sale of ZRT
        </li>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>
          Purchase of ZRT is final and there will be no refunds or cancellations
        </li>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>
          Please contact the Issuer for any inquiries regarding ZRT
        </li>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>
          Distribution schedule of ZRT is to be determined by the Issuer
        </li>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>
          <a href="#">View items</a>
        </li>
      </ul>
      <div style={{ height: '40px' }}></div>
      <h4>Notice for rates</h4>
      <ul>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>
          BTC, ETC, BNB: USD price as published by CoinMArketCap on https://coinmarketcap.com daily
          at 23^00 (UTC)
        </li>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>1 USDT = 1 USD</li>
        <li style={{ fontSize: '14px', padding: '5px 0' }}>
          PROB: the previous 10 minute volume weighted average price of PROB on ProBit Globals
          PROB/USDT market. (Price updated every minute)
        </li>
      </ul>
    </div>
  );
};

export default Cautions;
