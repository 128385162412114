import { Link } from 'react-router-dom';

import { ROUTE_FAQ } from 'src/routes';

import HelpComponent from '../../../components/help';
import { SecondTitle } from '../../../components/title';

const Help = () => {
  return (
    <div className="mt-5 pt-5">
      <SecondTitle text="Need Help?" />
      <div className="d-flex justify-content-around help-content-align">
        <a style={{ textDecoration: 'none' }} href="mailto:customercare@bxlend.com">
          <HelpComponent url="./assets/chat-support.png" title="Chat support" />
        </a>
        <Link style={{ textDecoration: 'none' }} to={ROUTE_FAQ}>
          <HelpComponent url="./assets/faq.png" title="FAQ" />
        </Link>
        <a style={{ textDecoration: 'none' }} href="https://medium.com/@bxlend">
          <HelpComponent url="./assets/blog.png" title="Blog" />
        </a>
      </div>
    </div>
  );
};

export default Help;
