export type BalanceProps = {
  balance: string;
};
const Balance = ({ balance }: BalanceProps) => {
  return (
    <div className="d-flex flex-column">
      <div className="withdraw-balance-title mb-2">BTC Balance</div>
      <div className="withdraw-balance-content">{`${balance} BTC`}</div>
    </div>
  );
};

export default Balance;
