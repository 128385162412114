import React from 'react';

const GenerateAddressBtn = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        borderRadius: 20,
        border: 'none',
        background: '#00feb9',
        color: '#111',
        padding: 15,
      }}
    >
      Generate address
    </button>
  );
};

export default GenerateAddressBtn;
