import React from 'react';

const SelectType = ({ selectedType, setSelectedType }) => {
  const handleTypeChange = (event) => {
    setSelectedType(event.currentTarget.value);
  };

  return (
    <div>
      <select
        value={selectedType}
        onChange={handleTypeChange}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '2.5vw',
          border: '1px solid #cfcfcf',
          borderRadius: '16px',
          padding: '0.88vh 0.75vw',
          width: 'auto',
          background: 'none',
          fontWeight: 500,
          cursor: 'pointer',
          outline: 'none',
        }}
      >
        <option value="Type" selected>
          Type
        </option>
        <option value="Deposit">Deposit</option>
        <option value="Withdraw">Withdraw</option>
      </select>
    </div>
  );
};

export default SelectType;
