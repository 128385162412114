import styled from 'styled-components';

export const StyledAside = styled.aside`
  min-width: 20vw;
  margin-left: -12px;
  height: 100%;
  padding-top: 6vw;

  & > ul > li {
    list-style: none;
    width: 100%;
  }

  & > ul > li > a {
    text-decoration: none;
    color: #1b224a;
    padding: 0.5vw 2vw;
    display: flex;
    align-items: center;
    gap: 1.5vw;
    width: 100%;
    transition: 0.2s ease-in-out all;
  }

  & > ul > li > a:hover {
    background: #172a4f;
    color: #fff;

    path {
      fill: #fff;
    }
  }

  & > ul > li > .active {
    background: #172a4f;
    color: #fff;

    path {
      fill: #fff;
    }
  }

  @media only screen and (max-width: 768px) {
    & > ul > li > a > svg {
      display: none;
    }
  }
`;

export const StyledAsideBtn = styled.button`
  border: none;
  background: none;
  padding: 2vh 2vw;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1vw;
`;

export const StyledBtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1vw;
`;

export const StyledTable = styled.div`
  -webkit-box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.2);
  width: -webkit-fill-available;
  margin-top: 3vw;
  border-radius: 20px;
  padding: 2% 1% 3%;
  position: relative;

  & > .mobile_view {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    height: auto;

    & > .desktop_view {
      display: none;
    }

    & > .mobile_view {
      display: block;

      & > div {
        grid-template-columns: auto;
        margin-bottom: 20px;

        & > span {
          padding: 5px 10px
        }
      }
    }
`;

export const StyledOrdersWrap = styled.div`
  display: flex;
  padding-right: 3vw;
  min-height: 83vh;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding-right: 0;

    & > aside {
      height: auto;
      width: calc(100% + 24px);
    }

    & > aside > ul > li > a {
      display: flex;
      justify-content: center;
    }
  }
`;

export const StyledButtonsRow = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 0 3vw 3vw;

  @media only screen and (max-width: 600px) {
    padding-left: 0;
    flex-direction: column;
  }
`;

export const StyledWrap = styled.div<{ $margin?: string }>`
  display: flex;
  flex-wrap: ${({ $margin }) => ($margin ? 'nowrap' : 'wrap')};
  gap: 10px;
  margin: ${({ $margin }) => $margin || 0};
  align-self: baseline;

  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
`;

export const StyledRow = styled.div<{ $columns: string; $isHeader?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $columns }) => $columns};
  background: ${({ $isHeader }) => ($isHeader ? '#F2F2F2' : 'none')};
  color: rgba(23, 42, 79, 0.69);
  font-weight: ${({ $isHeader }) => ($isHeader ? '500' : '400')};
  padding: ${({ $isHeader }) => ($isHeader ? '.6vw 2vw' : '0')};
  border-radius: ${({ $isHeader }) => ($isHeader ? '20px' : '0')};
`;

export const StyledDataRow = styled.div<{ $columns: string }>`
  display: grid;
  grid-template-columns: ${({ $columns }) => $columns};
  align-items: center;
  color: rgba(23, 42, 79, 0.69);
  font-weight: 500;
  padding: 0.6vw 2vw;
`;

export const StyledPaginationButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1vw;
  justify-content: center;
`;

export const StyledSelect = styled.select`
  border: 1px solid #cfcfcf;
  border-radius: 16px;
  padding: 0.75vh 0.75vw;
  cursor: pointer;
  outline: none;
`;

export const StyledButton = styled.button<{ width?: number; gap?: number; $hasDropDown?: boolean }>`
  display: flex;
  gap: ${({ gap }) => `${gap || 2.5}vw`};
  border: 1px solid #cfcfcf;
  border-radius: 16px;
  padding: 8px 10px;
  align-items: center;
  width: ${({ width }) => (width ? `${width}vw` : 'auto')};
  background: none;
  color: rgba(23, 42, 79, 0.69);
  font-weight: 500;
  align-self: baseline;
  width: ${({ $hasDropDown }) => ($hasDropDown ? 'auto' : '100%')};
  text-align: ${({ $hasDropDown }) => ($hasDropDown ? 'auto' : 'center')};

  .label {
    color: #172a4f;
  }

  .dropdown_wrap {
    display: flex;
    align-items: center;
    gap: 0.5vw;
  }

  @media only screen and (max-width: 600px) {
    flex-grow: 1;
    padding: 0.75vh 2vw;
  }

  @media only screen and (max-width: 600px) {
    justify-content: space-between;
  }
`;
