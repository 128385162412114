import React, { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchCurrencies } from 'src/store/slice/currencies';

import Title from '../../../components/title';
import Image from 'react-bootstrap/esm/Image';

import { IPairObject, ITableData } from '../../../interfaces';
import { getChange24, getCryptoCurrencyLogo, getCurrencyPairs } from 'src/constants';
import TableHeader from './TableHeader';

export type TableDataProps = {
  pairData: IPairObject[];
  tableData: ITableData[];
};

const Table: FC<TableDataProps> = ({ pairData, tableData }) => {
  const [filteredTableData, setFilteredTableData] = useState<ITableData[]>([]);
  const [innerWidth, setInnerWidth] = useState(0);

  const dispatch = useDispatch();
  const { currencies } = useSelector(({ currencies }) => currencies);

  useEffect(() => {
    if (pairData && tableData) {
      const resultTableData = pairData
        ?.map(({ currencies }) => {
          const pair = `${currencies[0]}/${currencies[1]}`;
          return tableData.find((item) => item.pair === pair);
        })
        .filter(Boolean);

      if (resultTableData) {
        const temp: ITableData[] | undefined = [];
        resultTableData.forEach((result) => {
          if (result) {
            temp.push(result);
          }
        });
        setFilteredTableData(temp);
      }
    }
  }, [pairData, tableData]);

  useEffect(() => {
    if (!currencies.length) {
      dispatch(fetchCurrencies());
    }

    const handleResize = () => setInnerWidth(window.innerWidth);
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Title text="Popular Cryptocurrencies" />
      <table className="table table-borderless mt-5">
        <TableHeader />
        <tbody>
          {filteredTableData?.length !== 0 &&
            filteredTableData
              ?.slice(0, 5)
              .map(({ pair, percent_change_24, last, volume }: any, index: number) => {
                return (
                  <tr className="table-content row mt-2" key={index}>
                    <td className="col-sm-3 col-4 justify-content-start align-items-center d-flex table-name ps-3">
                      <Image
                        src={getCryptoCurrencyLogo(
                          currencies,
                          getCurrencyPairs(pair)[0].toLowerCase(),
                        )}
                        width="24px"
                        height="24px"
                      />
                      {innerWidth < 768 ? (
                        <div>
                          <div style={{ marginLeft: innerWidth < 500 ? '4px' : '0.5rem' }}>
                            {getCurrencyPairs(pair)[0]}
                          </div>
                          <div className="second ms-2">{`${pair}`}</div>
                        </div>
                      ) : (
                        <>
                          <div className="ms-3">{getCurrencyPairs(pair)[0]}</div>
                          <div className="second ms-2">{`${pair}`}</div>
                        </>
                      )}
                    </td>
                    <td className="col-sm-3 col-2 justify-content-center align-items-center d-flex">
                      ${last}
                    </td>
                    {getChange24(percent_change_24) >= 0 ? (
                      <td className="col-sm-4 col-3 justify-content-center align-items-center d-flex percent-increase">
                        {getChange24(percent_change_24)}%
                      </td>
                    ) : (
                      <td className="col-sm-4 col-3 justify-content-center align-items-center d-flex percent-decrease">
                        {getChange24(percent_change_24)}%
                      </td>
                    )}
                    <td className="col-sm-2 col-3 justify-content-end align-items-center d-flex pe-5">
                      {parseFloat(volume).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
