import styled from 'styled-components';

export const StyledSubmitButton = styled.button`
  border-radius: 24px;
  border: none;
  padding: 5px 0;
  color: #fff;
  position: absolute;
  bottom: 5%;
  left: 10%;
  width: 80%;
`;

export const StyledNextButton = styled.button`
  border-radius: 24px;
  border: none;
  padding: 5px 0;
  color: #fff;
  position: absolute;
  bottom: 5%;
  left: 10%;
  width: 80%;
  background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
`;

export const StyledWrap = styled.div`
  max-width: 400px;
  border: 1px solid #ccc;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 4% 2%;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const StyledInput = styled.input`
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
  outline: none;
  text-align: center;
  border: 1px solid #ccc;
`;
