import React, { useState, useRef } from 'react';
import { StyledButton, StyledWrap } from './styled';

const CapturePicture = ({
  videoRef,
  isVideoStarted,
  uploadedImage,
  setUploadedImage,
  handleStartCamera,
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [file, setFile] = useState<null | File>(null);
  const canvasRef = useRef();

  const canvasWidth = 340.7;
  const canvasHeight = 255.53;

  const handleStopCamera = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const handleTakePicture = () => {
    const canvas = canvasRef.current;
    // @ts-expect-error TODO: Fix type
    const context = canvas.getContext('2d');
    // @ts-expect-error TODO: Fix type
    canvas.width = canvasWidth;
    // @ts-expect-error TODO: Fix type
    canvas.height = canvasHeight;
    context.drawImage(videoRef.current, 0, 0, canvasWidth, canvasHeight);
    // @ts-expect-error TODO: Fix type
    const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
    // @ts-expect-error TODO: Fix type
    canvas.toBlob((blob) => {
      const file = new File([blob], 'picture.jpeg', { type: 'image/jpeg' });
      setFile(file);
    }, 'image/jpeg');
    setImageSrc(dataUrl);
    handleStopCamera();
  };

  const handleDelete = () => {
    setUploadedImage(null);
    setImageSrc(null);
    handleStartCamera();
  };

  return (
    <>
      {uploadedImage && uploadedImage.name ? (
        <StyledWrap>
          <div>{uploadedImage.name}</div>
          <div style={{ cursor: 'pointer', transform: 'rotate(45deg)' }} onClick={handleDelete}>
            +
          </div>
        </StyledWrap>
      ) : (
        <div>
          {imageSrc ? (
            <>
              <div>
                <img
                  src={imageSrc}
                  alt="Captured image"
                  style={{ width: '100%', height: '255.53px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  paddingTop: '10px',
                }}
              >
                <StyledButton
                  onClick={() => {
                    setImageSrc(null);
                    setUploadedImage(null);
                    handleStartCamera();
                  }}
                >
                  Retake picture
                </StyledButton>
                <StyledButton onClick={() => setUploadedImage(file)} $isOtp>
                  Upload picture
                </StyledButton>
              </div>
            </>
          ) : (
            <div>
              <video ref={videoRef} style={{ width: '100%' }}></video>
              {/* @ts-expect-error TODO: Fix type */}
              <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isVideoStarted && (
                  <StyledButton onClick={handleTakePicture} $isOtp>
                    Take picture
                  </StyledButton>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CapturePicture;
