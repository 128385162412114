import React from 'react';
import FinancialCrime from '../../assets/FinancialCrime.jpeg';

const AboutUs = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h2>About Us</h2>
      <div style={{ padding: '20px 20%' }}>
        <p>
          Welcome to Bxlend, a cutting-edge crypto-banking platform dedicated to revolutionizing the
          way you manage and interact with your digital assets. Based in Lithuania, Bxlend operates
          under the registered name UAB Bxlend, registration number 306723160, and proudly holds a
          crypto exchange license. At Bxlend, we provide a comprehensive range of services designed
          to meet the diverse needs of cryptocurrency users across the European Union. Our offerings
          include secure and efficient management of your crypto assets, ensuring that your
          experience with us is both seamless and trustworthy. Our registration number stands as a
          testament to our commitment to compliance and excellence in the crypto space. This ensures
          that we adhere to the highest standards of security and reliability, giving our users
          peace of mind as they navigate the dynamic world of cryptocurrencies.
        </p>
        <div style={{ padding: '50px 15% 0' }}>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            <span>Licenses</span> & Registrations
          </div>
        </div>
      </div>
      <div
        style={{
          margin: '0 10%',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            border: '1px solid #ccc',
            padding: '20px',
            width: 'fit-content',
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <img src={FinancialCrime} width="80px" />
          </div>
          <div style={{ letterSpacing: '3px' }}>LITHUANIA</div>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            Financial Crime <br /> Investigation Service
          </div>
          <div style={{ paddingBottom: '30px', color: 'gray' }}>
            Execution of Virtual Currency Exchange <br /> Operator and Deposit of Virtual Currency{' '}
            <br /> Wallets Operator
          </div>
          <div style={{ paddingBottom: '20px', color: 'gray' }}>
            Reference NO. <br /> 306723160
          </div>
          <div style={{ paddingBottom: '20px', color: 'gray' }}>
            COMPANY <br /> BxLend UAB
          </div>
          <div style={{ paddingBottom: '30px', color: 'gray' }}>
            <a
              href="https://www.registrucentras.lt/jar/sarasai/vvko.php"
              style={{
                display: 'block',
                maxWidth: '100%',
                wordBreak: 'break-word',
                marginBottom: '20px',
              }}
            >
              https://www.registrucentras.lt/jar/sarasai/vvko.php
            </a>
            <a
              href="https://www.registrucentras.lt/jar/sarasai/dvvpo."
              style={{ display: 'block', maxWidth: '100%', wordBreak: 'break-word' }}
            >
              https://www.registrucentras.lt/jar/sarasai/dvvpo.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
