import React, { FC } from 'react';
import { Search } from 'react-bootstrap-icons';

import { useSelector } from 'react-redux';

import './style.css';

export type ISearchBox = {
  placeHolder?: string;
  keyword?: string;
  setKeyword?: React.Dispatch<React.SetStateAction<string>>;
};

const SearchBox: FC<ISearchBox> = ({ placeHolder, keyword, setKeyword }) => {
  const { isDark } = useSelector(({ isDark }) => isDark);

  return (
    <div className="input-group border p-1 search-box">
      <div className="input-group-prepend border-0">
        <button id="button-addon4" type="button" className="btn btn-link text-info">
          <Search />
        </button>
      </div>
      <input
        type="search"
        placeholder={placeHolder}
        aria-describedby="button-addon4"
        value={keyword}
        style={{ border: 'none', outline: 'none', background: isDark ? '#111' : '#fff' }}
        onChange={(e) => setKeyword && setKeyword(e.target.value.toString())}
      />
    </div>
  );
};

export default SearchBox;
