import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as EyeIcon } from '../../../assets/Eye.svg';

import NoResult from 'src/components/NoResult/NoResult';
import Badge from 'src/components/StatusBadge/Badge';
import Modal from './Modal';

import { convertDate } from 'src/constants';

import { StyledDataRow } from '../styled';

const DisplayItems = ({ items, columns }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    created_at: string;
    direction: string;
    owner_type: string;
    executed_price: string;
    quantity: string;
    status: string;
    updated_at: string;
    amount: string;
    kind: string;
    owner: string;
    wallets: string[];
    fee: {
      percentage: string;
      amount: string;
    };
  } | null>(null);

  const { isDark } = useSelector(({ isDark }) => isDark);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <div>
      {items.length ? (
        items.map((item) => (
          <StyledDataRow key={item.id} $columns={columns}>
            <span>
              <span>{convertDate(item.created_at).date}</span>
              <br />
              <span>{convertDate(item.created_at).time}</span>
            </span>
            {[item.pair, item.direction, item.owner_type, item.executed_price, item.quantity].map(
              (i) => (
                <span key={i}>{i}</span>
              ),
            )}
            <Badge value={item.status} />
            <span>{item.quantity}</span>
            <span onClick={() => openModal(item)} style={{ cursor: 'pointer' }}>
              <EyeIcon fill={isDark ? '#fff' : '#111'} />
            </span>
          </StyledDataRow>
        ))
      ) : (
        <NoResult styles={{ padding: '10% 0 5%' }} />
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal} isDark={isDark}>
        {selectedItem && (
          <div>
            {selectedItem.id && (
              <p>
                <strong>ID:</strong> {selectedItem.id}
              </p>
            )}
            {selectedItem.created_at && (
              <p>
                <strong>Created At:</strong> {convertDate(selectedItem.created_at).date}{' '}
                {convertDate(selectedItem.created_at).time}
              </p>
            )}
            {selectedItem.updated_at && (
              <p>
                <strong>Update At:</strong> {convertDate(selectedItem.updated_at).date}{' '}
                {convertDate(selectedItem.updated_at).time}
              </p>
            )}
            {selectedItem.direction && (
              <p>
                <strong>Direction:</strong> {selectedItem.direction}
              </p>
            )}
            {selectedItem.owner_type && (
              <p>
                <strong>Owner Type:</strong> {selectedItem.owner_type}
              </p>
            )}
            {selectedItem.owner && (
              <p>
                <strong>Owner ID:</strong> {selectedItem.owner}
              </p>
            )}
            {selectedItem.executed_price !== undefined && (
              <p>
                <strong>Executed Price:</strong> {selectedItem.executed_price}
              </p>
            )}
            {selectedItem.quantity !== undefined && (
              <p>
                <strong>Quantity:</strong> {selectedItem.quantity}
              </p>
            )}
            {selectedItem.status && (
              <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <strong>Status:</strong> <Badge value={selectedItem.status} />
              </p>
            )}
            {selectedItem.amount !== undefined && (
              <p>
                <strong>Amount:</strong> {selectedItem.amount}
              </p>
            )}
            {selectedItem.kind && (
              <p>
                <strong>Kind:</strong> {selectedItem.kind}
              </p>
            )}
            {selectedItem?.fee?.percentage !== undefined && (
              <p>
                <strong>Fee (percentage):</strong> {selectedItem.fee.percentage}
              </p>
            )}
            {selectedItem?.fee?.amount !== undefined && (
              <p>
                <strong>Fee (amount):</strong> {selectedItem.fee.amount}
              </p>
            )}
            {selectedItem?.wallets && selectedItem.wallets.length && (
              <p>
                <strong>Wallets:</strong>{' '}
                {selectedItem.wallets.map((wallet, i) => (
                  <span key={wallet}>
                    {wallet}
                    {i !== selectedItem.wallets.length - 1 ? ',' : ''}{' '}
                  </span>
                ))}
              </p>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DisplayItems;
