import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TableHead from './TableHead';
import NoResult from 'src/components/NoResult/NoResult';
import Dropdown from 'src/components/Dropdown';
import Pagination from 'src/components/Pagination';

import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import Loader from 'src/components/Loader/Loader';

import request from '../../../request';
import { PUBLIC_URL } from '../../../configs';
import { ROUTE_TRANSACTIONS } from 'src/routes';
import { ALL_FAV_BTNS } from 'src/constants';

export type ITransaction = {
  id: string;
  created_at: Date;
  quantity: string;
  status: string;
  currency: string;
};

const emptyLabelStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '100px',
};

const RecentTransactionsTable = () => {
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    request
      .get(`${PUBLIC_URL}/v1/transactions?page=${currentPage}&limit=5`)
      .then(({ data }) => {
        const sortedTransactions = data.transactions.sort((a, b) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        setTransactions(sortedTransactions);
        setTotalPages(data?.meta?.page_count);
      })
      .catch(({ response }) =>
        dispatch(
          setAppAlert({
            message: response?.data?.error || 'Something went wrong.',
            isSuccess: false,
          }),
        ),
      )
      .finally(() => setLoading(false));
  }, [dispatch, currentPage]);

  return (
    // @ts-expect-error TODO: Fix type issue
    <div className="w-100" style={transactions?.length ? undefined : emptyLabelStyles}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="dashboard-recent-trans-title">Recent Transactions</div>
        <div className="d-flex dashboard-recent-trans-dropdown dropdown px-2">
          <button type="button" className="btn" onClick={() => navigate(ROUTE_TRANSACTIONS)}>
            View All
          </button>
          <Dropdown>
            {ALL_FAV_BTNS.map((filterButton, index) => (
              <li key={index}>
                <button className="dropdown-item">{filterButton}</button>
              </li>
            ))}
          </Dropdown>
        </div>
      </div>
      {loading ? (
        <Loader size={100} />
      ) : transactions?.length ? (
        <table className="table table-borderless mt-4 dashboard-recent-trans-table">
          <TableHead />
          <tbody>
            {transactions
              ?.slice(0, 5)
              .map(
                ({ id, created_at, quantity, currency, status }: ITransaction, index: number) => {
                  return (
                    <tr className="table-content row border-top" key={index}>
                      <td className="col-xl-5 col-6 justify-content-center align-items-start d-flex flex-column table-name">
                        <div
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '150px',
                          }}
                        >
                          {id}
                        </div>
                      </td>
                      <td className="col-xl-3 col-0 justify-content-start align-items-center d-xl-flex d-none">
                        {new Intl.DateTimeFormat('en-US', {
                          month: 'short',
                          day: '2-digit',
                          year: 'numeric',
                        }).format(new Date(created_at))}
                      </td>
                      <td className="col-xl-2 col-3 justify-content-start align-items-center d-flex">
                        {`${quantity} ${currency}`}
                      </td>
                      <td className="col-xl-2 col-3 justify-content-start align-items-center d-flex pe-5">
                        {status}
                      </td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </table>
      ) : (
        <NoResult />
      )}
      {!loading && transactions.length ? (
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} nPages={totalPages} />
      ) : null}
    </div>
  );
};

export default RecentTransactionsTable;
