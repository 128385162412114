import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div<{ $isDark?: string }>`
  background: ${({ $isDark }) => ($isDark ? '#333' : '#fff')};
  color: ${({ $isDark }) => ($isDark ? '#fff' : '#333')};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.3s ease-out;
  max-width: 500px;
  width: 90%;
`;

const Modal = ({ isOpen, onClose, children, isDark }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.id === 'modal-background') {
        onClose();
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <ModalBackground id="modal-background">
      <ModalContent $isDark={isDark}>{children}</ModalContent>
    </ModalBackground>
  );
};

export default Modal;
