import React from 'react';

import { SOCIAL_MEDIAS } from 'src/constants';

import { StyledDatesWrap, StyledDetailsWrap, StyledText, StyledWrap } from './styled';

const GraphicsList = () => {
  return (
    <div style={{ padding: '0%' }}>
      <h4 style={{ textAlign: 'center', paddingBottom: '10px' }}>Details</h4>
      <div style={{ height: '30px' }}></div>
      <StyledDetailsWrap>
        <div style={{ minWidth: '50%' }}>
          <div style={{ borderBottom: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
            <StyledText>Name</StyledText>
            <span style={{ fontSize: '14px', paddingLeft: '8px' }}>Zororium</span>
          </div>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <StyledText>Ticker</StyledText>
            <span style={{ fontSize: '14px', paddingLeft: '8px' }}>ZRT</span>
          </div>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <StyledText>Price</StyledText>
            <span style={{ fontSize: '14px', paddingLeft: '8px' }}>1 ZRT = 0.00025 USDT</span>
          </div>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <StyledText>Bonus</StyledText>
            <span style={{ fontSize: '14px', paddingLeft: '8px' }}>
              10% when buying with PROB 5%
            </span>
          </div>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <StyledText>Hard cap</StyledText>
            <span style={{ fontSize: '14px', paddingLeft: '8px' }}>1,000,000 USDT</span>
          </div>
        </div>
        <div style={{ minWidth: '50%' }}>
          <div style={{ borderBottom: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
            <StyledText>Website</StyledText>
            <span>
              <a style={{ fontSize: '14px', paddingLeft: '8px' }} href="#">
                English
              </a>
            </span>
          </div>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <StyledText>Contract address</StyledText>
            <span>
              <a style={{ fontSize: '14px', paddingLeft: '8px' }} href="#">
                0x40db4356751a9015
              </a>
            </span>
          </div>
          <div>
            <StyledText>Token type</StyledText>
            <span style={{ fontSize: '14px', paddingLeft: '8px' }}>BEP-20</span>
          </div>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <StyledText>Whitepaper</StyledText>
            <span>
              <a style={{ fontSize: '14px', paddingLeft: '8px' }} href="#">
                English
              </a>
            </span>
          </div>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <StyledText>Social media</StyledText>
            <span>
              {SOCIAL_MEDIAS.map((s) => (
                <a key={s} style={{ fontSize: '14px', paddingLeft: '8px' }} href="#">
                  {s}
                </a>
              ))}
            </span>
          </div>
        </div>
      </StyledDetailsWrap>
      <div style={{ height: '50px' }}></div>
      <h4 style={{ textAlign: 'center' }}>Vesting schedule</h4>
      <div style={{ height: '30px' }}></div>
      <div style={{ textAlign: 'center' }}>
        <button
          style={{
            background: 'none',
            padding: '10px 0',
            textAlign: 'center',
            width: '35%',
            margin: '0 auto',
            border: '1px solid #ccc',
          }}
        >
          Learn more about vesting
        </button>
        <div style={{ height: '30px' }}></div>
        <StyledDatesWrap>
          <StyledWrap>
            <span style={{ fontWeight: 600 }}>Distribution date (UTC+3)</span>
            <span style={{ fontWeight: 600 }}>Distribution</span>
          </StyledWrap>
          <StyledWrap>
            <span>2023-11-10 11:00</span>
            <span>50%</span>
          </StyledWrap>
          <StyledWrap>
            <span style={{ fontWeight: 600 }}>2023-11-10 11:00</span>
            <span style={{ fontWeight: 600 }}>10%</span>
          </StyledWrap>
          <StyledWrap>
            <span>2023-11-10 11:00</span>
            <span>10%</span>
          </StyledWrap>
          <StyledWrap>
            <span>2023-11-10 11:00</span>
            <span>10%</span>
          </StyledWrap>
          <StyledWrap>
            <span>2023-11-10 11:00</span>
            <span>10%</span>
          </StyledWrap>
          <StyledWrap>
            <span>2023-11-10 11:00</span>
            <span>10%</span>
          </StyledWrap>
        </StyledDatesWrap>
      </div>
    </div>
  );
};

export default GraphicsList;
