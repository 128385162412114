import './style.css';

export interface IButtonType {
  text: string;
  onClick?: () => void;
}

function Button({ text, onClick }: IButtonType) {
  return (
    <button
      className="button-style d-flex align-items-center justify-content-center"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default Button;
