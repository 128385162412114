import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { ReactComponent as Bell } from '../../../assets/Bell.svg';
import Avatar from '../../../assets/Avatar.svg';

import OptionsModal from './OptionsModal';

import { ROUTE_OPEN_ORDERS, ROUTE_ORDERS, ROUTE_WALLET } from 'src/routes';

import { StyledLoggedInIcons, StyledLoggedInLinks, StyledCount } from './styled';
import ThemeToggle from './ThemeToggle';

const LoggedNav = () => {
  const [isModal, setIsModal] = useState(false);

  const { notificationsCount } = useSelector(({ notificationsCount }) => notificationsCount);

  return (
    <>
      <StyledLoggedInLinks>
        <Link
          className="link"
          to={`${ROUTE_ORDERS}${ROUTE_OPEN_ORDERS}`}
          style={{ justifyContent: 'flex-end !important' }}
        >
          Orders
        </Link>
        <Link className="link" to={ROUTE_WALLET}>
          Wallet
        </Link>
        <ThemeToggle />
      </StyledLoggedInLinks>
      <StyledLoggedInIcons>
        <Link to="/notifications">
          <div style={{ position: 'relative' }}>
            {notificationsCount ? <StyledCount>{notificationsCount}</StyledCount> : null}
            <Bell />
          </div>
        </Link>
        <span style={{ position: 'relative' }}>
          <img
            src={Avatar}
            onClick={() => setIsModal((prevState) => !prevState)}
            style={{ cursor: 'pointer' }}
            alt="notification-avatar"
          />
          {isModal && <OptionsModal handleClose={() => setIsModal(false)} />}
        </span>
      </StyledLoggedInIcons>
    </>
  );
};

export default LoggedNav;
