import styled from 'styled-components';

export const StyledBonusWrap = styled.div`
  display: flex;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;

    & > div {
      max-width: 100% !important;
    }

    & > div:nth-child(1) {
      height: 400px;
    }
  }
`;

export const StyledDetailsWrap = styled.div`
  display: flex;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const StyledDatesWrap = styled.div`
  width: 50%;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    width: auto;
  }
`;

export const StyledText = styled.span`
  font-size: '14px';
  background: '#eee';
  min-width: '130px';
  display: 'inline-block';
  padding: '10px 8px';
  font-weight: 600;
`;

export const StyledWrap = styled.div`
  padding: '4px 26px';
  border-bottom: '1px solid #ccc';
  display: 'flex';
  justify-content: 'space-between';
`;
