import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'src/store/useDispatch';
import { useSelector } from 'react-redux';
import { fetchCountries } from 'src/store/slice/countries';
import { setAppAlert } from 'src/store/slice/appAlert';

import { ReactComponent as BgSmall } from '../../assets/BgSmall.svg';
import { ReactComponent as BgLarge } from '../../assets/BgLarge.svg';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';
import { getDocTypeConverted } from 'src/constants';

import CountryInput from '../AccountCreated/VerificationPopUp/CountryInput';
import UploadButton from '../AccountCreated/VerificationPopUp/UploadButton';
import Checkbox from '../AccountCreated/VerificationPopUp/Checkbox';
import CapturePicture from '../AccountCreated/VerificationPopUp/CapturePicture';
import Loader from 'src/components/Loader/Loader';

import {
  StyledCheckboxLabel,
  StyledInputContent,
  StyledSquare,
} from '../AccountCreated/VerificationPopUp/styled';
import { StyledButton } from '../EnterEmail/styled';
import { StyledWrap } from './styled';
import './index.css';

const EarlyRegistration = () => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [docType, setDocType] = useState('');
  const [address, setAddress] = useState<string>('');
  const [pinCode, setPinCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [privacyAgreed, setPrivacyAgreed] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<null | File>(null);
  const [frontImage, setFrontImage] = useState<null | File>(null);
  const [backImage, setBackImage] = useState<null | File>(null);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docError, setDocError] = useState('');
  const videoRef = useRef();

  const { countries } = useSelector(({ countries }) => countries);
  const dispatch = useDispatch();

  const { code } = countries.find((c: { name: string; code: string }) => c.name === query) ?? {
    code: '',
  };

  const docLabel = () => {
    if (!uploadedImage && !frontImage && !backImage) {
      return '(Optional)';
    }
    if (
      uploadedImage &&
      frontImage &&
      backImage &&
      docType !== '' &&
      docType !== 'Select document type'
    ) {
      return '';
    }

    return '(Required)';
  };

  const isDocsCheckPassed = () => {
    if (!uploadedImage && !frontImage && !backImage) {
      return true;
    }
    if (uploadedImage && (!frontImage || !backImage)) {
      return false;
    }
    if (frontImage && (!uploadedImage || !backImage)) {
      return false;
    }
    if (backImage && (!frontImage || !uploadedImage)) {
      return false;
    }
    if (
      uploadedImage &&
      frontImage &&
      backImage &&
      docType !== '' &&
      docType !== 'Select document type'
    ) {
      return true;
    }
  };

  const isSubmitActive =
    firstName &&
    lastName &&
    address &&
    isEmailValid &&
    code &&
    pinCode &&
    city &&
    termsAgreed &&
    privacyAgreed &&
    isDocsCheckPassed();

  const handleStartCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // @ts-expect-error TODO: Fix type
        videoRef.current.srcObject = stream;
        // @ts-expect-error TODO: Fix type
        videoRef.current.play();
      })
      .catch((error) => console.error(error));
  };

  const resetState = () => {
    setFirstName('');
    setEmail('');
    setQuery('');
    setMiddleName('');
    setLastName('');
    setAddress('');
    setPinCode('');
    setCity('');
    setTermsAgreed(false);
    setPrivacyAgreed(false);
    setUploadedImage(null);
    setFrontImage(null);
    setBackImage(null);
    setIsVideoStarted(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      const type = docType === 'Passport' ? docType.toUpperCase() : getDocTypeConverted(docType);

      if (uploadedImage) formData.append('waiting_list_user_document', uploadedImage);
      if (frontImage) formData.append('waiting_list_user_document', frontImage);
      if (backImage) formData.append('waiting_list_user_document', backImage);
      formData.append('name[first]', firstName);
      formData.append('name[last]', lastName);
      formData.append('country_code', code);
      formData.append('email', email);
      formData.append('address[city]', city);
      formData.append('address[pin_code]', pinCode);
      formData.append('address[full_address]', address);
      formData.append('terms_and_conditions_consent', termsAgreed.toString());
      formData.append('privacy_policy_consent', privacyAgreed.toString());
      formData.append('identification_type', type);

      await request
        .post(`${PUBLIC_URL}/waiting-list/register`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        })
        .then(({ data }) => {
          dispatch(
            setAppAlert({
              message: data?.msg || 'You have submitted your data.',
              isSuccess: true,
            }),
          );
          resetState();
        })
        .catch(({ response }) =>
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Something went wrong',
              isSuccess: false,
            }),
          ),
        )
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('An error occurred while submitting the form:', error);
    }
  };

  const validateEmail = (password) => {
    const passwordRegex =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsEmailValid(passwordRegex.test(password));
  };

  useEffect(() => {
    if (!uploadedImage && !frontImage && !backImage) {
      setDocError('');
      return;
    }
    if (uploadedImage && (!frontImage || !backImage)) {
      setDocError('You must either upload all docs or none of them.');
      return;
    }
    if (frontImage && (!uploadedImage || !backImage)) {
      setDocError('You must either upload all docs or none of them.');
      return;
    }
    if (backImage && (!frontImage || !uploadedImage)) {
      setDocError('You must either upload all docs or none of them.');
      return;
    }
    if (uploadedImage && frontImage && backImage) {
      setDocError('');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImage, frontImage, backImage, docType]);

  useEffect(() => {
    if (!countries.length) {
      dispatch(fetchCountries());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledWrap>
        <h3>Fill out registration form below:</h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div>
            <div>Country/region</div>
            <CountryInput countries={countries} query={query} setQuery={(q) => setQuery(q)} />
          </div>
          <div>
            <div>First Name</div>
            <StyledInputContent
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </div>
          <div>
            <div>Middle Name (optional)</div>
            <StyledInputContent
              value={middleName}
              onChange={(e) => setMiddleName(e.currentTarget.value)}
            />
          </div>
          <div>
            <div>Last Name</div>
            <StyledInputContent
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
          </div>
          <div style={{ padding: '0 0 15px' }}>
            <div>Email</div>
            <StyledInputContent
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
                validateEmail(e.currentTarget.value);
              }}
            />
            {!isEmailValid && email && <p style={{ color: 'red' }}>Invalid email</p>}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div>
            <div>Full Address</div>
            <StyledInputContent
              value={address}
              onChange={(e) => setAddress(e.currentTarget.value)}
            />
          </div>
          <div>
            <div>Pincode</div>
            <StyledInputContent
              value={pinCode}
              onChange={(e) => setPinCode(e.currentTarget.value)}
            />
          </div>
          <div style={{ padding: '0 0 15px' }}>
            <div>City</div>
            <StyledInputContent value={city} onChange={(e) => setCity(e.currentTarget.value)} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div>
            <div>
              <div>Document type {docLabel()}</div>
              <select
                style={{
                  width: '100%',
                  padding: '8px 15px',
                  borderRadius: '20px',
                  border: '1px solid #ccc',
                  marginBottom: '20px',
                  background: 'transparent',
                }}
                value={docType}
                onChange={(e) => setDocType(e.currentTarget.value)}
              >
                <option style={{ color: '#111 !important' }} value="Select document type">
                  Select document type
                </option>
                <option style={{ color: '#111' }} value="Passport">
                  Passport
                </option>
                <option style={{ color: '#111' }} value="Identity card">
                  Identity card
                </option>
                <option style={{ color: '#111' }} value="Driving license">
                  Driving license
                </option>
              </select>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'center',
                paddingBottom: '20px',
              }}
            >
              {docError && <span style={{ color: 'red' }}>{docError}</span>}
              <UploadButton text="Front" file={frontImage} setFile={setFrontImage} />
              <UploadButton text="Back" file={backImage} setFile={setBackImage} />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {isVideoStarted ? (
            <CapturePicture
              videoRef={videoRef}
              isVideoStarted={isVideoStarted}
              handleStartCamera={handleStartCamera}
              setUploadedImage={setUploadedImage}
              uploadedImage={uploadedImage}
            />
          ) : (
            <StyledSquare
              onClick={() => {
                handleStartCamera();
                setIsVideoStarted(!isVideoStarted);
              }}
            >
              <span style={{ border: '2px solid dotted', color: '#fff', padding: '10px 20px' }}>
                Submit <br /> Live <br /> Selfie
              </span>
            </StyledSquare>
          )}
          <ul style={{ paddingBottom: '20px' }}>
            <li style={{ fontWeight: 500 }}>* Only images are allowed to be uploaded.</li>
            <li>Do not hide parts of your face with hats or glasses.</li>
            <li>Take a picture in well-lit area.</li>
          </ul>
        </div>
        <div style={{ paddingBottom: '20px' }}>
          <>
            <div>
              <div>
                <Checkbox isChecked={termsAgreed} setIsChecked={setTermsAgreed}>
                  <StyledCheckboxLabel>
                    I agree with{' '}
                    <a target="_blank" href="/term-of-use">
                      terms and conditions
                    </a>
                  </StyledCheckboxLabel>
                </Checkbox>
              </div>
              <div>
                <Checkbox isChecked={privacyAgreed} setIsChecked={setPrivacyAgreed}>
                  <StyledCheckboxLabel>
                    I agree with{' '}
                    <a target="_blank" href="/privacy-policy">
                      privacy and policy
                    </a>
                  </StyledCheckboxLabel>
                </Checkbox>
              </div>
            </div>
          </>
        </div>
        <StyledButton
          onClick={handleSubmit}
          disabled={loading}
          $isActive={!!isSubmitActive}
          style={{ width: '100%' }}
        >
          {loading ? <Loader size={24} /> : 'Submit'}
        </StyledButton>
      </StyledWrap>
      <div className="bg_wrap">
        <BgSmall className="bg_small" />
        <BgLarge />
      </div>
    </>
  );
};

export default EarlyRegistration;
