import React, { FC } from 'react';

import Button from './Button';
import Text from 'src/components/Text/Text';

import { StyledButtonsRow, StyledWrap } from '../styled';
import SelectType from './SelectType';

interface IButtonsRow {
  buttons: { id: number; text: string; hasDropDown: boolean; gap?: number; caption?: string }[];
  hasDateBtn?: boolean;
  hasAsideBtns?: boolean;
  hasSearch: boolean;
  selectedType?: string;
  setSelectedType?: any;
}

const ButtonsRow: FC<IButtonsRow> = ({ buttons, hasSearch, selectedType, setSelectedType }) => {
  return (
    <StyledButtonsRow>
      <StyledWrap>
        {buttons.map(({ text, hasDropDown, gap, id, caption }) =>
          text === 'Deposit' ? (
            <SelectType key={id} selectedType={selectedType} setSelectedType={setSelectedType} />
          ) : (
            <Button key={id} text={text} hasDropDown={hasDropDown} gap={gap} caption={caption} />
          ),
        )}
        {hasSearch && (
          <div className="caption_wrap">
            <Text size={12} color="rgba(23, 42, 79, 0.69)" weight={500} padding="0 0 0.6vw">
              TxID
            </Text>

            <input className="input_search" type="text" placeholder="Enter TxID" />
          </div>
        )}
      </StyledWrap>
    </StyledButtonsRow>
  );
};

export default ButtonsRow;
