import styled from 'styled-components';

export const StyledInputEmail = styled.input`
  border: 1px solid #ccc;
  padding: 8px 20px;
  border-radius: 40px;
  width: 100%;
  outline: none;
  color: #111 !important;
`;

export const StyledInputEmailWrap = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const StyledButton = styled.button<{ $isActive?: boolean }>`
  border: none;
  background: ${({ $isActive }) => ($isActive ? '#00FEB9' : '#ccc')};
  pointer-events: ${({ $isActive }) => ($isActive ? 'auto' : 'none')};
  padding: 12px 0;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 40px;
  color: #111 !important;
  outline: none;
`;

export const StyledSendCode = styled.span`
  position: absolute;
  top: 25%;
  right: 20px;
  cursor: pointer;
  color: #111 !important;

  @media (max-width: 1024px) {
    right: -20px;
  }
`;
