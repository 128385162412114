import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

import { ReactComponent as Avatar } from '../../assets/Avatar.svg';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchNotificationsCount } from 'src/store/slice/notificationsCount';
import { fetchTickers } from 'src/store/slice/tickers';
import { fetchWalletAddresses } from 'src/store/slice/walletAddresses';

import BreadCrumb from './components/BreadCrumb';
import TopMarketTable from './components/TopMarketTable';
import RecentTransactionTable from './components/RecentTransactionTable';
import Dropdown from 'src/components/Dropdown';

import { KYC_STATUS } from 'src/constants';
import { ITableData } from '../../interfaces';
import { ROUTE_ACCOUNT_CREATED, ROUTE_DEPOSIT, ROUTE_WITHDRAW } from 'src/routes';

import './index.css';

export type IWallet = {
  balance: string;
  currency: string;
  kind: string;
  owner: string;
  owner_type: string;
};

export type IUser = {
  name: {
    first: string;
    last: string;
  };
  id: string;
  kyc_status: string;
};

const Dashboard = () => {
  const [fiatUsdBalance, setFiatUsd] = useState(0.01);
  const [cryptoUsdBalance, setCryptoUsd] = useState(0.01);
  const [hkdUsd, setHkdUsd] = useState<ITableData>();
  const [isBalanceVisible, setBalanceVisible] = useState(true);

  const { tickers, loading } = useSelector(({ tickers }) => tickers);
  const { walletAddresses } = useSelector(({ walletAddresses }) => walletAddresses);
  const { isDark } = useSelector(({ isDark }) => isDark);
  const {
    user: { email_verified, twoFA_verified, kyc_status, name, id },
  } = useSelector(({ user }) => user);

  const token = localStorage.getItem('access');
  const dispatch = useDispatch();

  const toggleShowBalance = () => {
    setBalanceVisible(!isBalanceVisible);
  };

  const navigate = useNavigate();

  const calculateUSD = (currency: string, balance: string) => {
    const lastValue = tickers.find((data) => data.pair === `${currency.toUpperCase()}/USD`)?.last;
    if (lastValue) {
      return parseFloat(balance) * parseFloat(lastValue);
    }
    return 0;
  };

  const handleVerificationBeforeNavigate = (path: string) => {
    const url =
      email_verified && twoFA_verified && kyc_status === KYC_STATUS.VERIFIED
        ? path
        : ROUTE_ACCOUNT_CREATED;
    const state =
      email_verified && twoFA_verified && kyc_status === KYC_STATUS.VERIFIED
        ? null
        : { message: 'Verify your email, kyc and 2FA to use deposit and withdrawal service' };
    navigate(url, { state });
  };

  const getAllBalance = () => {
    let fiat = 0.0;
    let crypto = 0.0;
    walletAddresses.forEach((wallet) => {
      if (wallet.kind === 'CRYPTO') {
        crypto += calculateUSD(wallet.currency, wallet.balance);
      } else if (wallet.kind === 'FIAT') {
        if (wallet.currency === 'HKD') {
          fiat += parseFloat(wallet.balance) * parseFloat(hkdUsd?.last || '0');
        } else {
          fiat += parseFloat(wallet.balance);
        }
      }
    });
    setCryptoUsd(crypto);
    setFiatUsd(fiat);
  };

  useEffect(() => {
    if (!tickers.length) {
      dispatch(fetchTickers());
    } else {
      const hkdUsdPair = tickers.find((data) => data.pair === 'HKD/USD');
      setHkdUsd(hkdUsdPair);
    }

    if (!walletAddresses.length) {
      dispatch(fetchWalletAddresses());
    }

    if (token) {
      dispatch(fetchNotificationsCount());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (walletAddresses.length > 0 && tickers?.length > 0) {
      getAllBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddresses, tickers]);

  return (
    <div className="container-fluid px-sm-5 px-2">
      <BreadCrumb />
      <div className="d-lg-flex dashboard-basic-info">
        <div
          className={`col-lg-6 col-12 dashboard-user-info d-flex align-items-center p-4 ${
            isDark ? 'isDark' : ''
          }`}
        >
          <Avatar className="avatar_rounded" />
          <div className="d-flex flex-column ps-2">
            {name ? (
              <div className="dashboard-user-name h2">{`${name?.first ?? ''} ${
                name?.last ?? ''
              }`}</div>
            ) : null}
            <div className="d-flex align-items-center">
              <div>User Id:</div>
              <div className="ms-5">{id}</div>
            </div>
            <div className="d-flex">
              <div>KYC Verification Status:</div>
              <div className="ms-5">
                {kyc_status === KYC_STATUS.VERIFIED || kyc_status === KYC_STATUS.PENDING
                  ? kyc_status
                  : 'Please submit your information to get approved link:'}
                {kyc_status !== KYC_STATUS.VERIFIED && kyc_status !== KYC_STATUS.PENDING && (
                  <Link to="/account-created" className="ms-2">
                    Account created
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-lg-6 col-12 dashboard-total-balance d-flex justify-content-between align-items-center p-5 ms-lg-4 mt-lg-0 mt-sm-4 mt-4 ${
            isDark ? 'isDark' : ''
          }`}
        >
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <div className="h4">Total Balance</div>
              <div
                className={`d-none dashboard-total-balance-dropdown dropdown px-2 mb-2 ms-5 ${
                  isDark ? 'isDark' : ''
                }`}
              >
                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">
                  Deposit
                </button>
                <Dropdown>
                  <li>
                    <button className="dropdown-item">Deposit</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Withdraw</button>
                  </li>
                </Dropdown>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="light-color-font">USD Balance</div>
              <button className="btn ms-2 balance-toggle-btn" onClick={toggleShowBalance}>
                {isBalanceVisible ? (
                  <AiFillEyeInvisible width="20px" height="20px" />
                ) : (
                  <AiFillEye width="20px" height="20px" />
                )}
              </button>
            </div>
            <div className="h1">
              {isBalanceVisible
                ? `${(fiatUsdBalance + cryptoUsdBalance).toFixed(3)} USD`
                : '*** *** ***'}
            </div>
            <div className="light-color-font">Wallet ID: 2QVT7C0LOWEJ</div>
          </div>
          <div className="dashboard-balance-btn-group d-flex flex-column justify-content-between">
            <button
              className="blank-button dashboard-transaction-btn  d-flex justify-content-center align-items-center mt-2"
              onClick={() => handleVerificationBeforeNavigate(ROUTE_DEPOSIT)}
            >
              Deposit
            </button>
            <button
              className="blank-button dashboard-transaction-btn  d-flex justify-content-center align-items-center mt-2"
              onClick={() => handleVerificationBeforeNavigate(ROUTE_WITHDRAW)}
            >
              Withdraw
            </button>
          </div>
        </div>
      </div>
      <div className="d-lg-flex my-5">
        <div
          className={`col-lg-7 col-12 dashboard-top-market d-flex align-items-center py-3 px-5 ${
            isDark ? 'isDark' : ''
          }`}
        >
          <TopMarketTable tableData={tickers} loading={loading} />
        </div>
        <div
          className={`col-lg-5 col-12 dashboard-recent-transactions d-flex align-items-center p-5 ms-lg-4 mt-lg-0 mt-sm-4 mt-4 ${
            isDark ? 'isDark' : ''
          }`}
        >
          <RecentTransactionTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
